import React from "react";
import colors from "../colors";

export const ErrorPage: React.FC = () => {
  return <div className="ErrorPage">
    <h1>Our server is under maintenance..⌛</h1>
    <p>
        Please try again later.
    </p>
    <style>
      {`
            .ErrorPage {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                text-align: center;
                color: ${colors.white2}
            }
        `}
    </style>
  </div>
}
