import axios, { AxiosResponse } from "axios";
import PageEditDto from "./dtos/PageEditDto";
import PageCreateDto from "./dtos/PageCreateDto";
import PageDto from "./dtos/PageDto";
import PageSubdomainUpdateDto from "./dtos/PageSubdomainUpdateDto";

export function create(dto: PageCreateDto): Promise<AxiosResponse<PageDto>> {
  return axios.post("/api/page/create", dto, { withCredentials: true });
}

export function getPage(cache = true): Promise<AxiosResponse<PageDto[]>> {
  return axios.get(`/api/page/me?date=${cache ? "" : new Date().getTime()}`
      , { withCredentials: true });
}

export function update(dto: PageEditDto): Promise<AxiosResponse> {
  return axios.put("/api/page/update", dto, { withCredentials: true });
}

export function updateSubdomain(dto: PageSubdomainUpdateDto):
 Promise<AxiosResponse> {
  return axios.put("/api/page/update-subdomain",
      dto, { withCredentials: true });
}

export function deletePage(pageId: number): Promise<AxiosResponse> {
  return axios.delete(`/api/page/delete/${pageId}`, { withCredentials: true });
}

export function getPublicPage(subdomain: string)
  : Promise<AxiosResponse<PageDto>> {
  return axios.get(`/api/page/view/${subdomain}`, { withCredentials: false });
}
