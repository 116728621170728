import React, { useContext, useEffect, useState } from "react";
import { getPage } from "../../api/PageAPI";
import PageDto from "../../api/dtos/PageDto";
import { AppContext, AppContextAction } from "../AppContext";
import { AxiosResponse } from "axios";
import DefaultButton from "../common/DefaultButton";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../common/LoadingState";
import { Spinner } from "../common/Spinner";
import AuthHeader from "../header/AuthHeader";
import { WebPageItem } from "./WebPageItem";
import { BiPlus } from "react-icons/bi";
import { UpgradeModal } from "../upgrade/UpgradeModal";
import { useTranslation } from "react-i18next";

const Profile: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate()
  const pages = state.pages.state === LoadingState.LOADED ?
  state.pages.data : null;
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [onlyPremiumPlan, setOnlyPremiumPlan] = useState(false)
  const { t } = useTranslation()

  const isDeviceUser = state.userDto.state === LoadingState.LOADED &&
   state.userDto.data?.deviceId === state.userDto.data?.email

  if (state.userDto.state === LoadingState.NO_AUTH) {
    navigate("/sign-in")
  }

  useEffect(() => {
    if ( !!localStorage.getItem("selectedPremium") ||
    !!localStorage.getItem("isUpgradeBannerClicked")) {
      setShowUpgradeModal(true)
      setOnlyPremiumPlan(true)
    }
    fetchPages();
  }, []);

  async function fetchPages(): Promise<void> {
    try {
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.LOADING },
      })
      const pageResponse: AxiosResponse<PageDto[]> = await getPage(false);
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.LOADED, data: pageResponse.data },
      });
    } catch (error) {
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.FAILED, errorMessage: error.message },
      });
    }
  }


  function handleCreatePageClick() {
    navigate("/templates")
  }

  const plusIcon = <BiPlus size="25" color={colors.white2} />


  if ( state.pages.state === LoadingState.LOADING) {
    return <Spinner />
  }

  function websitesInfoText() {
    if ( !pages?.length ) {
      return t("you-have-no-website(s)")
    }
    if ( pages?.length === 1 ) {
      return t("you-have-a-website")
    }
    return t("you-have-websites", { count: pages?.length })
  }

  return <div className="Dashboard">
    <AuthHeader showRegisterDeviceButton={isDeviceUser} />
    {showUpgradeModal && <UpgradeModal
      onlyPremiumPlan={onlyPremiumPlan}
      isAuth={true} onClose={() => {
        setShowUpgradeModal(false); setOnlyPremiumPlan(false)
      }} />}
    <div className="Dashboard-Title">
      <h1>Dashboard</h1>
      <h3>{ websitesInfoText() }</h3>
    </div>
    <div className="WebPage-Item-List">
      {
        pages?.map?.((p, i) => {
          return <WebPageItem templateName={p.templateName}
            pageId={p.id} key={p.id} index={pages.length - 1 - i} />
        })
      }
    </div>
    <div className="Dashboard-Create">
      <div className="Dashboard-Create-New-Website-Btn">
        <DefaultButton icon={plusIcon}
          color="blue"
          size="medium"
          text={t("new-website")} onClick={handleCreatePageClick} />
      </div>
    </div>
    <style>
      {`
        .Dashboard {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 8em;
          color: ${colors.white2};
          min-height: 100vh;
          max-width: 750px;
          width: 100%;
        }
        .WebPage-Item-List {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1em;
          padding: 1em 0.5em;
          box-sizing: border-box;
        }
        .Webpage-Item {
          max-width: 500px;
        }

        .Dashboard-Create {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: start;
          flex-direction: column;
          padding: 1em;
          box-sizing: border-box;
        }
        .Dashboard-Create-New-Website-Btn {
          width: 12em;
        }
        .Dashboard-Title {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          padding-left: 1em;
          box-sizing: border-box;
        }
      `}
    </style>
  </div>;
};

export default Profile;
