import React, { useContext } from "react";
import { MdDone, MdOutlineModeEditOutline } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { isProduction } from "../../config";
import colors from "../colors";
import { removeNonAlphaNumeric } from "../dashboard/Subdomain.util";
import { updateSubdomain } from "../../api/PageAPI";
import { AppContext, AppContextAction } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import ErrorMessage from "../ErrorMessage";
import { Spinner } from "../common/Spinner";

type UpdateState = "IDLE" | "SAVING" | "EDITING" | "ERROR"

type Props = {
  pageId: number
}

export const AddressBar: React.FC<Props> = ({ pageId }) => {
  const [updateState, setUpdateState] = React.useState<UpdateState>("IDLE")
  const [errorMessage, setErrorMessage] = React.useState("")
  const [updatedSubdomain, setUpdatedSubdomain] = React.useState(null)
  const protocol = isProduction ? "https://" : "http://"
  const domain = isProduction ? ".lovepage.io" : ".localhost:3000"
  const { state, dispatch } = useContext(AppContext);

  if (!pageId) return null

  if ( state.pages.state !== LoadingState.LOADED ) return null

  const pages = state.pages.data
  const page = pages.find((page) => page.id === pageId)
  if (!page) return null
  const pageUrl = protocol + page.subdomain + domain

  function handleAddressInput(e: React.FormEvent<HTMLSpanElement>) {
    // @ts-ignore
    const val = escapeDomainInput(e.target.innerText);
    setUpdatedSubdomain(val)

    if ( errorMessage ) setErrorMessage("")
  }

  function escapeDomainInput(val: string) {
    val = val.toLowerCase()
    val = removeNonAlphaNumeric(val)
    return val
  }

  function handleEditAddressFocus() {
    document.execCommand("selectAll", false, null);
    // collapse selection to the end
    document.getSelection().collapseToEnd();
    setUpdateState("EDITING")
  }

  function handleEditAddressClick() {
    // @ts-ignore
    document.querySelector(`.AddressBar-Subdomain-${pageId}`).focus();
    handleEditAddressFocus()
  }

  async function handleSaveAddressClick() {
    if ( updatedSubdomain === null || updatedSubdomain === page.subdomain ) {
      setUpdateState("IDLE")
      return;
    }

    setUpdateState("SAVING")
    try {
      if (updatedSubdomain.length < 4) {
        setErrorMessage("🚫 Subdomain must be at least 4 characters long.")
        setUpdateState("ERROR")
        return
      }
      const response = await updateSubdomain({
        pageId: page.id,
        newSubdomain: updatedSubdomain,
      });
      const updatedPages = pages.map((page) => {
        if (page.id === pageId) {
          return { ...page, subdomain: response.data.subdomain };
        }
        return { ...page };
      });

      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.LOADED, data: updatedPages },
      });
      setUpdateState("IDLE")
    } catch (error) {
      console.log(error);

      setUpdateState("ERROR")
      if ( error.response.status === 422 ) {
        setErrorMessage("🚫 This address is already taken.")
      } else {
        setErrorMessage(error.response.data.message);
      }
    }
  }

  const showSaveButton = updateState === "EDITING" || updateState === "ERROR"
  const showEditButton = updateState === "IDLE"
  const showSpinner = updateState === "SAVING"

  return <div className="AddressBar">
    <div className="AddressBar-First-Row">
      <div
        onClick={handleEditAddressClick}
        className="AddressBar-Text-Container">
        <span onInput={handleAddressInput}
          onFocus={handleEditAddressFocus}
          onBlur={handleSaveAddressClick}
          suppressContentEditableWarning={true}
          className={`AddressBar-Subdomain-${pageId}`}
          contentEditable>
          {page?.subdomain}
        </span>
        <span className="AddressBar-Domain">.lovepage.io</span>
      </div>
      <div className="AddressBar-Actions">
        {showEditButton &&
        <div onClick={handleEditAddressClick}
          className="AddressBar-Action-Item">

          <MdOutlineModeEditOutline
            color="white" size={24} />
        </div>
        }
        {showSaveButton &&
        <div className="AddressBar-Action-Item">

          <MdDone onClick={handleSaveAddressClick}
            color="white" size={24} />
        </div>
        }
        {showSpinner &&
        <div className="AddressBar-Action-Item">
          <Spinner />
        </div>
        }
        <a href={pageUrl}
          target="_blank" rel="noreferrer"
          className="AddressBar-Action-Item">
          <IoMdEye color="white" size={24} />
        </a>
      </div>
    </div>
    {errorMessage?.length > 0 &&
    <div className="AddressBar-Second-Row">
      <ErrorMessage message={errorMessage} />
    </div>
    }
    <style>
      {`
        .AddressBar-Action-Item {
            height: 24px;
            width: 24px;
        }
        .AddressBar-First-Row {
            height: 2em;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
        }
         .AddressBar {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          .AddressBar-Text-Container {
            background-color: ${colors.white2};
            color: ${colors.grayDark2};
            font-size: 1em;
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%;
            box-sizing: border-box;
            overflow: hidden;
            border-radius: 3px;
            border: 3px solid ${colors.gray};
            height: 2em;
            padding: 1px 1px 0 1px;
            box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
            cursor: text;
            font-family: 'Monaco', sans-serif;
          }
          .AddressBar-Subdomain-${pageId}, .AddressBar-Domain {
            height: 90%;
            display: flex;
            align-items: center;
            box-sizing: border-box;
          }
          .AddressBar-Subdomain-${pageId}:focus {
            margin-right: 3px;
          }
          .AddressBar-Domain {
            padding: 3px 1px 3px 0;
            box-sizing: border-box;
          }
          .AddressBar-Subdomain-${pageId} {
            padding: 3px 0 3px 1px;
            overflow: scroll;
            box-sizing: border-box;
            overflow-y: hidden;
            text-align: left;
          }
          .AddressBar-Subdomain-${pageId}:focus {
            margin: 2px 1px 2px 2px;
          }
          .AddressBar-Action-Item {
            background-color: rgb(242, 242, 247, 0.1);
            padding: 5px;
            border-radius: 5px;
            cursor: pointer;
            box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
          }
          .AddressBar-Action-Item:hover {
            background-color: rgb(0, 0, 0, 0.2);
          }
          .AddressBar-Actions {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 5px;
            padding: 0 5px;
          }
          @media only screen and (max-width: 500px) {

          }
        `}
    </style>
  </div>
}

