import React, { useState } from "react";
import Input from "../common/Input";
import DefaultButton from "../common/DefaultButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import colors from "../colors";
import { startPasswordReset } from "../../api/AuthApi";
import Header from "../header/Header";


const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isSendBtnSpin, setSendBtnSpin] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const isEmailSent = !!searchParams.has("confirmation")

  function handleEmailChange(val: string) {
    setErrorMessage("")
    setEmail(val)
  }

  async function handleSendClick() {
    try {
      setSendBtnSpin(true)
      await startPasswordReset(email)
      navigate("/password-reset?confirmation")
    } catch (error) {
      if ( error?.response?.status === 422 ) {
        setErrorMessage("This email is not registered")
      }
    } finally {
      setSendBtnSpin(false)
    }
  }

  if (isEmailSent) {
    return (
      <div className="PasswordReset">
        <Header />
        <h1>Check your email</h1>
        <p>
        We have sent a password reset link to your email address.
        </p>
        <style>
          {`
            .PasswordReset {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: ${colors.white2}
            }
        `}
        </style>
      </div>
    )
  }

  return (
    <div className="PasswordReset-Page">
      <Header />
      <div className="PasswordReset">
        <h3 className="PasswordReset-Header">Password Reset Form</h3>
        <p>We will send a password reset link to your email address</p>
        <div className="PasswordReset-Form">
          { !!errorMessage && <ErrorMessage message={errorMessage} /> }
          <Input isError={!!errorMessage}
            defaultValue="" onChange={handleEmailChange}
            placeholder="Email Address" />
          <div className="PasswordReset-Form-Button">
            <DefaultButton isSpin={isSendBtnSpin}
              text="Send" onClick={handleSendClick} />
          </div>
        </div>
        <style>
          {`
            .PasswordReset-Page {
              height: 100%;
            }
            .PasswordReset {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                gap: 1em;
                width: 100%;
                color: ${colors.white2};
                min-height: 70vh;
            }
            .PasswordReset-Header {
                font-size: 1.5em;
                padding: 0 1em 1em 1em;
            }   
            .PasswordReset-Form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1em;
                padding: 1em;
            }
            .PasswordReset-Form-Button {
                width: 100%;
                max-width: 200px;
            }
            .PasswordReset > p {
                padding: 0 1em;
            }
        `}
        </style>
      </div>
    </div>
  )
};

export default PasswordReset;
