import React from "react";
import colors from "../colors";
import { MdOutlineModeEditOutline } from "react-icons/md";

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  size?: "small" | "large";
  isDisabled?: boolean;
}

const EditButtonCircle: React.FC<Props> = ({ onClick, size,
  isDisabled = false }) => {
  const iconSize = size === "small" ? 20 : 30;
  return <button tabIndex={0}
    disabled={isDisabled} className="EditButtonCircle-Container"
    onClick={onClick}>
    <MdOutlineModeEditOutline
      style={{ fill: colors.white }}
      className="EditButtonCircle" size={iconSize} />
    <style>
      {`
        .EditButtonCircle-Container {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${colors.grayDark};
          border-radius: 50%;
          padding: 4px;
          cursor: pointer;
          border: 1px solid ${colors.white2};
        }
        .EditButtonCircle-Container:hover {
          -webkit-filter: brightness(70%);;
        }
        .EditButtonCircle-Container:disabled {
          filter: brightness(30%);
          --webkit-filter: brightness(30%);
          border: none;
          cursor: not-allowed;
        }
      `}
    </style>
  </button>;
};

export default EditButtonCircle;
