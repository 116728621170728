import React, { useContext, useEffect, useState } from "react";
import Input from "../common/Input";
import { AppContext, AppContextAction } from "../AppContext";
import ErrorMessage from "../ErrorMessage";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../common/LoadingState";
import DefaultButton from "../common/DefaultButton";
import { BiLogIn } from "react-icons/bi";
import colors from "../colors";
import { Footer } from "../homepage/Footer";
import { validateEmail } from "./Validator.util";
import AuthHeader from "../header/AuthHeader";
import { setEmailForDeviceUser } from "../../api/UserApi";
import { useTranslation } from "react-i18next";

const CreateAccount: React.FC = () => {
  const [email, setEmail]= useState("");
  const { dispatch } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate()
  const [isSigninButtonSpin, setSigninButtonSpin] = useState(false)
  const [redirectAfterEmail, setRedirectAfterEmail] = useState("")
  const { t } = useTranslation()

  useEffect(() => {
    const redirectPath = sessionStorage.getItem("redirectAfterEmail")
    if (redirectPath) {
      sessionStorage.removeItem("redirectAfterEmail")
      setRedirectAfterEmail(redirectPath)
    }
  }, []);

  function handleEmailChange(value: string) {
    setErrorMessage("");
    setEmail(value);
  }

  async function handleSaveClick(): Promise<void> {
    try {
      setSigninButtonSpin(true)
      const user = await setEmailForDeviceUser(email)
      dispatch({
        type: AppContextAction.SIGN_IN,
        userDto: { state: LoadingState.LOADED, data: user },
      });
      localStorage.removeItem("deviceId");
      // @ts-ignore
      window.OpenReplay?.setUserID(email);
      if (redirectAfterEmail) {
        navigate(redirectAfterEmail)
      } else {
        navigate("/dashboard")
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message ||
         "Server is offline, we are working on it");
      console.error(error);
      return;
    } finally {
      setSigninButtonSpin(false)
    }
  }

  const signInIcon = <BiLogIn color={colors.white2}
    className="Sign-In-Icon" size={35} />

  return <><div className="CreateAccount">
    <AuthHeader showRegisterDeviceButton={false} />
    <h2 className="CreateAccount-Title">{ t("enter-an-email-address") }</h2>
    <p className="Create-Account-Sub">
      { t("enter-an-email-address-sub") }
    </p>
    <div className="CreateAccount-Email row">
      <Input placeholder={t("email-address")} onChange={handleEmailChange}
        isError={!!email && validateEmail(email) !== null}
        defaultValue={email} textAlign="left" />
    </div>
    <div className="CreateAccount-Button row">
      <DefaultButton
        isSpin={isSigninButtonSpin}
        disabled={isSigninButtonSpin || validateEmail(email) !== null}
        className="CreateAccount-Button-CreateAccount-Btn"
        color="blue"
        text={t("save-button")} onClick={handleSaveClick} />
    </div>
    <ErrorMessage message={errorMessage} />
    <style>
      {`
        .or-descriptive {
          color: ${colors.white2};
          margin: 2em 0 1em 0;
        }
        .CreateAccount-Title {
          padding: 1em 0 0 0;
        }
        .CreateAccount {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          color: ${colors.white2};
          padding-bottom: 4em;
          min-height: 70vh;
         }
         .row {
          display: flex;
          justify-content: center;
          margin: 10px 0;
         }
         .CreateAccount-Button {
          display: flex;
          flex-direction: column;
          max-width: 325px;
         }
          .CreateAccount-Button-CreateAccount-Btn:disabled {
            cursor: not-allowed;
          }
        .Create-Account-Sub {
          padding: 0 1em;
        }
      `}
    </style>
  </div>
  <Footer />
  </>
};

export default CreateAccount;
