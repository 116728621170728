import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import colors from "../colors";
import { css, cx } from "@emotion/css";
import AddActionButtons from "./AddActionButtons";

type Props = {
    onPhotoClick: () => void;
    onTextClick: () => void;
}

export const AddItemsCircleButton: React.FC<Props> =
({ onPhotoClick, onTextClick }) => {
  const [showActions, setShowActions] = React.useState(false)

  function handleBlur() {
    setTimeout(() => {
      setShowActions(false)
    }, 150)
  }
  const styles = css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        position: fixed;
        bottom: 2.5em;
        left: 1em;
        `

  const spinStyle = css`
        animation: spin 0.5s;
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(45deg);
            }
        }
        animation-fill-mode: forwards;
        background-color: ${colors.grayDark2}};
    `

  const plusBtnStyle = css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        border: 1px solid ${colors.white2};
        background-color: ${colors.grayDark};
        cursor: pointer;
    `

  const actionBtnsStyle = css`
        &::after {
          opacity: 0;
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-width: 14px;
          border-style: solid;
          border-color: transparent 
          ${colors.grayDark2} transparent transparent;
          left: 68px;
          top: 13px;
          animation: showit 0.5s;
          @keyframes showit {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          animation-fill-mode: forwards;
        }
        animation: movebtns 0.5s;
        @keyframes movebtns {
            0% {
              margin-left: 0em;
              opacity: 0;
            }
            100% {
              margin-left: 1em;
              opacity: 1;
            }
        }
        animation-fill-mode: forwards;
  `

  return <div className={styles}>
    <button id="add-item-circle-button"
      onBlur={handleBlur} onClick={() => setShowActions((v) => !v)}
      className={showActions ? cx(plusBtnStyle, spinStyle) :
        plusBtnStyle}>
      <AiOutlinePlus size={80} color={colors.white2} />
    </button>
    {showActions &&
     <div className={actionBtnsStyle}>
       <AddActionButtons onPhotoClick={onPhotoClick}
         onTextClick={onTextClick}/>
     </div>
    }
  </div>
}
