import React, { useEffect, useRef } from "react";
import colors from "../../colors";
import { cx, css } from "@emotion/css";
import "@fontsource-variable/lora";
import { observer } from "mobx-react-lite";
import "@fontsource/alex-brush";

type Props = {
    text: string;
    onChange: (text: string) => void;
    className: string;
    textKey: string
}

const MAX_LENGTH = 165

export const EditableDiv: React.FC<Props> =
 observer(({ text, onChange, className, textKey }) => {
   const ref = useRef<HTMLDivElement>(null);
   const [initialText, setInitialText] = React.useState<string>(text);
   useEffect(() => {
     setInitialText(text);
   }, [textKey])


   function handleInput(e: React.FormEvent<HTMLDivElement>) {
     if (e.currentTarget.innerText.length > MAX_LENGTH) {
       e.currentTarget.innerText =
       e.currentTarget.innerText.slice(0, MAX_LENGTH)
       document.execCommand("selectAll", false, null);
       // collapse selection to the end
       document.getSelection().collapseToEnd();
     }
     onChange(e.currentTarget.innerText);
   }


   function handleFocus() {
     window.getSelection().selectAllChildren(ref.current)
     window.getSelection().collapseToEnd()
   }


   const styles = css`
    color: ${colors.grayDark2};
    padding: 5px;
    margin: 0;
    border: 1px dashed black;
    &:focus {
        border: 1px dashed transparent;
    }
    font-weight: bold;
    font-family: 'Lora Variable', sans-serif;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
    position: relative;
    min-width: 100%;
    `;


   return <div onFocus={handleFocus}
     ref={ref}
     onInput={handleInput}
     suppressContentEditableWarning={true}
     contentEditable={true}
     className={cx(styles, className)}>
     {initialText}
   </div>;
 },
 )
