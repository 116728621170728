import React, { useEffect, useRef } from "react";
import colors from "../../../colors";
import { EditableH3 } from "../../editable/EditableH3";
import { EditableP } from "../../editable/EditableP";
import EditButtonCircle from "../../../common/EditButtonCircle";
import imageCompression from "browser-image-compression";
import { getImage } from "../../../../utils/FileReader";
import { EditPageState } from "../../state/EditPageState";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import { LoadingBar } from "../../LoadingBar";
import { Spinner } from "../../../common/Spinner";

type Props = {
  photoIndex: number;
  state: EditPageState;
}

export const CollageItemEditView: React.FC<Props> = observer(({
  photoIndex,
  state,
}) => {
  const [progress, setProgress] = React.useState(0);
  const fileInputRef = useRef(null);
  const [isDisabled, setDisabled] = React.useState(false);

  useEffect(() => {
    if (progress === 0 && isDisabled === true) {
      setDisabled(false);
    } else if (progress > 0 && isDisabled === false) {
      setDisabled(true);
    }
  }, [progress]);


  function handleHeaderChange(text: string) {
    state.updateItem(photoIndex + 4, text);
  }
  function handleParagraphChange(text: string) {
    state.updateItem(photoIndex + 8, text);
  }

  function handleProgress(_progress: number) {
    setProgress(_progress % 100);
  }

  function handlePhotoEditClick() {
    fileInputRef.current.click();
  }

  async function handleFileInputChange(e) {
    if (e?.target?.files?.length === 0) {
      return;
    }
    const compressedImage = await imageCompression(e.target.files[0], {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
      useWebWorker: false,
      onProgress: (_progress) => handleProgress(_progress),
    });
    const imageAsUrl = await getImage(compressedImage);
    action(() => {
      state.updateItem(photoIndex, imageAsUrl);
    })();
  }

  return <div className="CollageItemEditView">
    <div className="CollageItemEditView-Photo-Container">
      <img style={{ filter: isDisabled ? "brightness(30%)" : "none" }}
        className="CollageItemEditView-Photo-Container-Photo"
        src={state.items[photoIndex].data}
        alt="photo for anniversary website" />
      {isDisabled && <LoadingBar progress={progress} /> }
      {isDisabled &&
       <div className="CollageItemEditView-Spinner">
         <Spinner />
       </div>
      }
      <input ref={fileInputRef} data-testid="select-image-input"
        onChange={handleFileInputChange} id="file-input" type="file"
        accept="image/png, image/gif, image/jpeg"/>
      <div className="CollageItemEditView-PhotoView-Edit-Btn">
        <EditButtonCircle isDisabled={isDisabled}
          onClick={handlePhotoEditClick} />
      </div>
    </div>
    <EditableH3
      text={state.items[photoIndex + 4].data}
      key={state.items[photoIndex + 4].key}
      textKey={state.items[photoIndex + 4].key}
      onChange={handleHeaderChange}
      className="CollageItemEditView-Calendar"/>
    <EditableP text={state.items[photoIndex + 8].data}
      textKey={state.items[photoIndex + 8].key}
      onChange={handleParagraphChange}
      className="CollageItemEditView-Paragraph"
    />
    <style>
      {`
        [data-testid="select-image-input"] {
          display: none;
        }
        .CollageItemEditView-PhotoView-Edit-Btn {
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .CollageItemEditView-Photo-Container-Photo {
          width: 90vw;
          max-width: 460px;
          height: 40vh;
          object-fit: contain;
          background-color: ${colors.white2};
          border: 1px dashed black;
          outline: 2px solid ${colors.white2};
          box-shadow: 0 0 2px 0 rgba(0,0,0,0.7);
        }
        .CollageItemEditView-Photo-Container {
          max-height: 70vh;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: relative;
        }
        .CollageItemEditView {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 10px;
            align-items: center;
            width: 98%;
            height: 100%;
            border: 15px solid ${colors.white2};
            box-sizing: border-box;
            background-color: ${colors.white2};
            border-radius: 2px;
            box-shadow: 0 0 5px 0 rgba(0,0,0,0.7);
           }
        .CollageItemEditView-Calendar {
            margin: 0;
            color: ${colors.purple}
        }
        .CollageItemEditView-Spinner {
          position: absolute;
          top: 35%;
        }
        `}
    </style>
  </div>;
},
)
