import React, { useEffect, useRef } from "react";
import { BsImage } from "react-icons/bs";
import { getImage } from "../../utils/FileReader";
import imageCompression from "browser-image-compression";
import colors from "../colors";
import { ItemActions } from "./ItemActions";
import { Spinner } from "../common/Spinner";
import PhotoView from "../page/PhotoView";
import { LoadingBar } from "./LoadingBar";

type Props = {
  image: string;
  onChange: (index: number, val: string) => void;
  onDelete: (index: number) => void;
  onUpClick: (index: number) => void;
  onDownClick: (index: number) => void;
  index: number;
  isDndTarget: boolean;
}
const SelectImage: React.FC<Props> = ({ image, onChange,
  onDelete, onDownClick, onUpClick, index, isDndTarget }) => {
  const fileInputRef = useRef(null);
  const [progress, setProgress] = React.useState(0);
  const [isDisabled, setDisabled] = React.useState(false);
  const [showActions, setShowActions] = React.useState(false);

  useEffect(() => {
    if (progress === 0 && isDisabled === true) {
      setDisabled(false);
    } else if (progress > 0 && isDisabled === false) {
      setDisabled(true);
    }
  }, [progress]);

  function handleProgress(_progress: number) {
    setProgress(_progress % 100);
  }


  async function handleFileInputChange(e) {
    if (e?.target?.files?.length === 0) {
      return;
    }
    const compressedImage = await imageCompression(e.target.files[0], {
      maxSizeMB: 0.1,
      maxWidthOrHeight: 1920,
      useWebWorker: false,
      onProgress: (_progress) => handleProgress(_progress),
    });
    const imageAsUrl = await getImage(compressedImage);
    onChange(index, imageAsUrl);
  }
  function handleFileInputClick() {
    fileInputRef.current.click();
  }

  function handleDelete() {
    onDelete(index);
  }

  return (
    <div style={{ border: isDndTarget ?
       "3px dotted aqua" : "none" }}
    className="SelectImage">
      <input ref={fileInputRef} data-testid="select-image-input"
        onChange={handleFileInputChange} id="file-input" type="file"
        accept="image/png, image/gif, image/jpeg"/>
      {!!image &&
      <div onFocus={() => setShowActions(true)}
        onBlur={() => {
          setTimeout(() => setShowActions(false), 200);
        }}
        tabIndex={index}
        className="SelectImage-Image">
        <PhotoView isDisabled={isDisabled} image={image} size="full" />
        {isDisabled && <LoadingBar progress={progress} /> }
        {isDisabled && <div className="SelectImage-PlaceHolder-Spinner">
          <Spinner />
        </div>
        }
      </div>
      }
      {!image && <div className="SelectImage-Placeholder"
        onClick={!image ? handleFileInputClick : () => null}>
        {
          isDisabled === false && <><BsImage className="SelectImage-Icon"
            style={{ color: colors.white2 }}
            size="100" />
            Click here to select image
          </>
        }
        {isDisabled && <LoadingBar progress={progress} /> }
        {isDisabled && <div className="SelectImage-PlaceHolder-Spinner">
          <Spinner />
        </div>
        }
      </div>
      }
      {showActions &&
        <div className="SelectImage-Actions">
          <ItemActions
            isDisabled={isDisabled}
            onUpClick={() => onUpClick(index)}
            onDownClick={() => onDownClick(index)}
            onDeleteClick={handleDelete}
            onEditClick={handleFileInputClick}
          />
        </div>
      }
      <style>
        {`
            img:hover {
              cursor: pointer;
            }

            img {
              height: 100%;
              width: 100%;
              display: inline-block;
              vertical-align: middle;
              max-height: 70vh;
            }

            .SelectImage {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              box-sizing: unset;
            }
              
            .SelectImage-Image {
              position: relative;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
              box-sizing: border-box;
              border-top: 3px solid transparent;
              border-bottom: 3px solid transparent;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
            }

            .SelectImage-Placeholder {
              width: 100%;
              height: 100%;
              min-height: 300px;
              min-width: 300px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              color: ${colors.white2};
              border: 1px dashed ${colors.white2};
              border-radius: 5px;
              cursor: pointer;
              position: relative;
              box-sizing: border-box;
            }
            
            .SelectImage-Actions {
              display: flex;
              justify-content: center;
              padding: 0.5em 1em 1em 1em;
              position: absolute;
              bottom: 0;
              pointer-events: auto;
            }

            .SelectImage-Image:focus-within {
              border-top: 3px solid #007cff;
              border-bottom: 3px solid #007cff;
              border-left: 6px solid #007cff;
              border-right: 6px solid #007cff;
            }

            .SelectImage input {
              display: none;
            }
            .SelectImage-PlaceHolder-Spinner {
              position: absolute;
              top: 20%;
            }
          `}
      </style>
    </div>
  );
};

export default React.memo(SelectImage);
