import React from "react";
import colors from "../colors";
import { css } from "@emotion/css";
import DefaultButton from "../common/DefaultButton";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";

type Props = {
    onUpgradeClick: () => void;
    isAuth: boolean;
}

export const FreePlan: React.FC<Props> = ({
  onUpgradeClick,
  isAuth,
}) => {
  const navigate = useNavigate();

  const styles = css`
    width: 100%;
    display: flex;
    justify-content: center;
    .Premium-FreePlan {
        height: fit-content;
        width: 80%;
        min-width: 300px;
        max-width: 300px;
        max-height: 500px;
        box-shadow: 0px 0px 10px 0px ${colors.gray};
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-right: 100%;
        animation: slideIn 1s ease-in-out;
        animation-fill-mode: forwards;
        @keyframes slideIn {
          0% {
            margin-right: 100%;
            opacity: 0;
          }
          100% {
            margin-right: 0%;
            opacity: 1;
          }
        }
      }
    .Premium-FreePlan-Content {
        border: 1px solid ${colors.gray};
        border-top: none;
        border-radius: 5px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: fit-content;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 2em;
    }
    .Premium-FreePlan-Title {
        display: flex;
        justify-content: center;
    }
    .Premium-FreePlan-Price {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: sans-serif;
    }
    .Price-First, .Price-Second {
        font-weight: bold;
    }
    .Price-First {
        font-size: 2.3em;
    }
    .Price-Second {
        font-size: 1.5em;
    }
    .Price-Sign {
        font-size: 2em;
    }
    .Calendar-Text, .Premium-FreePlan-Notice {
        display: flex;
        justify-content: center;
        padding: 5px;
    }
    .Premium-FreePlan-Notice {
        margin-top: 25px;
        color: ${colors.grayDark2};
    }
    .Premium-FreePlan-Features {
        border-top: 1px solid ${colors.gray};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em 0;
        margin-top: 1em;
        width: 70%;
        box-sizing: border-box;
    }
    .Premium-FreePlan-Feature {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        gap: 8px;
    }
    .Premium-FreePlan-Banner {
        background-color: ${colors.lightBlue};
        display: flex;
        justify-content: center;
        padding: 5px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .UpgradeButton-Container {
        width: 200px;
    }
    .Premium-FreePlan-Content > s {
        text-decoration: line-through;
        text-decoration-thickness: 2px;
        margin-top: -10px;
        margin-bottom: 5px;
    }
    `

  const buttonText = isAuth ? "Upgrade" : "Sign Up";

  if ( !isAuth ) {
    onUpgradeClick = () => {
      navigate("/sign-in")
    }
  }
  return <div className={styles}>
    <div className="Premium-FreePlan">
      <div className="Premium-FreePlan-Banner">
        🔥
      </div>
      <div className="Premium-FreePlan-Content">
        <div className="Premium-FreePlan-Title">
          <h1>Free</h1>
        </div>
        <div className="Premium-FreePlan-Notice">
            No start-up costs
        </div>
        <div className="Premium-FreePlan-Features">
          <div className="Premium-FreePlan-Feature">
            <FaCheck size={20} />
          Free Templates
          </div>
          <div className="Premium-FreePlan-Feature">
            <FaCheck size={20} />
          Unlimited websites
          </div>
        </div>
        {!isAuth && <div className="UpgradeButton-Container">
          <DefaultButton className="Upgrade-Button"
            onClick={onUpgradeClick}
            text={buttonText} color="lightBlue" size="medium" />
        </div>
        }
      </div>
    </div>
  </div>
}
