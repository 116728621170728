import { useEffect, useState } from "react";
import PageDto from "../../api/dtos/PageDto";
import { getPublicPage } from "../../api/PageAPI";
import { LoadingState } from "../../common/LoadingState";
import { ViewState } from "../../common/ViewState";


export function usePageViewFetch(subdomain: string): ViewState<PageDto> {
  const [viewState, setViewState] =
  useState<ViewState<PageDto>>({ state: LoadingState.IDLE })

  if ( !subdomain ) {
    return { state: LoadingState.IDLE }
  }

  async function fetchPage(subdomain: string) {
    try {
      setViewState({ state: LoadingState.LOADING })
      const pageDtoResponse = await getPublicPage(subdomain);
      setViewState({
        state: LoadingState.LOADED,
        data: pageDtoResponse.data,
      })
    } catch (error) {
      setViewState({ state: LoadingState.FAILED, errorMessage: error })
      console.error(error);
    }
  }

  useEffect(() => {
    fetchPage(subdomain);
  }, [subdomain]);

  return viewState
}
