import axios, { AxiosResponse } from "axios";
import UserDto from "./dtos/UserDto";

export async function getUser(): Promise<UserDto> {
  try {
    const response: AxiosResponse<UserDto> =
    await axios.get(`/api/user/me?date=${new Date().getTime()}`,
        { withCredentials: true });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function logout(): Promise<void> {
  await axios.post("/api/auth/logout",
      null, { withCredentials: true });
}

export async function deleteAccount(): Promise<void> {
  await axios.delete("/api/user/delete", { withCredentials: true });
}

export async function createDeviceUser(deviceId: string): Promise<UserDto> {
  const response: AxiosResponse<UserDto> =
   await axios.post("/api/user/create-device-user",
       { deviceId }, { withCredentials: true });
  return response.data;
}

export async function setEmailForDeviceUser(email: string): Promise<UserDto> {
  const response: AxiosResponse<UserDto> =
    await axios.put("/api/user/set-email",
        { email }, { withCredentials: true });
  return response.data;
}
