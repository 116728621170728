import React, { useEffect } from "react";
import { Spinner } from "./common/Spinner";

export const LoadingPage: React.FC = () => {
  useEffect(() => {
    if ( window.location.pathname === "/upgrade-redirect" ) {
      localStorage.setItem("isUpgradeBannerClicked", "true")
      window.location.href = "/dashboard"
    }
  }, [])

  return <div className="LoadingPage">
    <div className="LoadingPage-Spinner">
      <Spinner />
    </div>
    <div className="LoadingPage-Text">
            Loading...
    </div>
    <style>
      {`
            .LoadingPage {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100vh;
            }
            .LoadingPage-Text {
                color: white;
                font-size: 1.5em;
                margin-top: 1em;
            }
            .LoadingPage-Spinner {
                height: 20vh;
            }
        `}
    </style>
  </div>
}
