import axios, { AxiosResponse } from "axios";
import UserDto from "./dtos/UserDto";
import { SignInResultDto } from "./dtos/SignInResultDto";
import { SignInDto } from "./dtos/SignInDto";

export async function signIn(signInDto: SignInDto):
 Promise<SignInResultDto> {
  const response: AxiosResponse<SignInResultDto> =
   await axios.post("/api/auth/sign-in",
       signInDto,
       { withCredentials: true });
  return response.data;
}

export async function getMe(): Promise<UserDto> {
  const response: AxiosResponse<UserDto> = await
  axios.get(`/api/user/me?date=${new Date().getTime()}`,
      { withCredentials: true });
  return response.data;
}

export async function validateSignInCode(code: string): Promise<UserDto> {
  const response: AxiosResponse<UserDto> =
  await axios.post("/api/auth/sign-in-code",
      { code },
      { withCredentials: true });
  return response.data;
}

export async function startPasswordReset(email: string): Promise<void> {
  await axios.post("/api/auth/start-password-reset", { email });
}

export async function postSetPassword(token: string, password: string):
 Promise<void> {
  await axios.post("/api/auth/set-password", { token, password });
}

export async function signInWithDeviceId(deviceId: string): Promise<void> {
  return axios.post("/api/auth/sign-in-with-device-id", { deviceId },
      { withCredentials: true });
}
