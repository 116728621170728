import React from "react";
import colors from "../colors";
import { IoCloseSharp } from "react-icons/io5";

type Props = {
    children: React.ReactNode;
    footer: React.ReactNode;
    onClose: () => void;
}
const Dialog: React.FC<Props> =
({ children, footer = null, onClose }) => {
  const closeIcon = <>
    <IoCloseSharp onClick={onClose}
      className="Close-Icon" size={40} />
    <style>
      {`
      .Close-Icon path {
        stroke: ${colors.white};
      }
      .Close-Icon {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    `}
    </style>
  </>;
  return <>
    <dialog className="Dialog" open>
      { closeIcon }
      <div className="DialogBody">
        { children }
      </div>
      <div className="DialogFooter">
        { footer }
      </div>
    </dialog>
    <style>
      {`
        .Dialog {
            color: ${colors.white2};
            background-color: ${colors.purple};
            border: 1px solid ${colors.white2};
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            z-index: 1;
            padding: 1em 2em;
            border-radius: 5px;
            width: 500px;
            position: fixed;
            top: 30%;
            box-sizing: border-box;
        }
        .DialogBody {
            width: 100%;
        }
        .DialogFooter {
            width: 100%;
        }
        @media screen and (max-width: 500px) {
          .Dialog {
            padding: 1em 0.5em;
            width: 100%;
          }
        }
     `}
    </style>
  </>;
};

export default Dialog;
