import React, { useContext, useState } from "react";
import { AddressBar } from "../edit/AddressBar";
import DefaultButton from "../common/DefaultButton";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { WebPageItemDropdown } from "./WebPageItemDropdown";
import { deletePage } from "../../api/PageAPI";
import { LoadingState } from "../../common/LoadingState";
import { AppContext, AppContextAction } from "../AppContext";
import { QrCodeDialog } from "./QrCodeDialog";
import Dialog from "../dialog/Dialog";
import { DeletePageDialogBody } from "./DeletePageDialogBody";
import { DeletePageDialogFooter } from "./DeletePageDialogFooter";
import { css } from "@emotion/css";
import { TemplateName } from "../create/TemplateName";
import { useTranslation } from "react-i18next";

type Props = {
    pageId: number
    index: number
    templateName: TemplateName
}
export const WebPageItem: React.FC<Props> = ({ pageId,
  index,
  templateName }) => {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(AppContext);
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleteButtonSpin, setDeleteButtonSpin] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("")
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language;

  function handleGenerateQrCodeClick() {
    setShowQrCodeDialog(true);
  }

  function handleEditClick() {
    navigate(`/edit?pageId=${pageId}`)
  }

  function handleDeleteConfirm() {
    setDeleteButtonSpin(true);
    setDeleteErrorMessage("");
    deletePage(pageId)
        .then(() => {
          setDeleteButtonSpin(false);
          setShowDeleteDialog(false);
          if (state.pages.state !== LoadingState.LOADED) return;
          const updatedPages = state.pages.data
              .filter((page) => page.id !== pageId);
          dispatch({
            type: AppContextAction.PAGE_LOAD,
            pages: { state: LoadingState.LOADED, data: updatedPages },
          })
        },
        )
        .catch((e) => {
          setDeleteButtonSpin(false);
          setDeleteErrorMessage(e.message);
        },
        );
  }

  function handleQrDialogClose() {
    setShowQrCodeDialog(false);
  }

  function handleDeletePageClick() {
    setShowDeleteDialog(true);
  }

  const deletePageFooter = <DeletePageDialogFooter
    isDeleteButtonSpin={isDeleteButtonSpin}
    onDeleteCancel={() => setShowDeleteDialog(false)}
    onDeleteConfirm={handleDeleteConfirm}
  />

  const templateNameStr = templateName === TemplateName.TIMELINE ?
  t("timeline-romance") : t("anniversary")

  const pageNameStyle = css`
        background-color: ${templateName === TemplateName.TIMELINE ?
           colors.lightBlue : colors.premiumPurple};
        border: none;
        padding: 5px 10px;
        border-radius: 15px;
        font-weight: bold;
        margin-bottom: 5px;
  `

  return <div className="WebPageItem">
    <div className={pageNameStyle}>
      {`#${index + 1} - ${templateNameStr} ${t("page")}`}
    </div>
    <div className="WebPageItem-Link">
      <AddressBar pageId={pageId} />
    </div>
    <div className="WebPageItem-Actions">
      <div className="WebPageItem-Action-Item">
        <DefaultButton className="WebPageItem-Edit-Button"
          size="medium" color="blue" text="Edit"
          onClick={handleEditClick} />
      </div>
      <div className="WebPageItem-Action-Item">
        <WebPageItemDropdown onDeleteClick={handleDeletePageClick}
          onQrCodeClick={handleGenerateQrCodeClick}
          className={`PageLinkDropdown-${pageId}`}
          pageId={pageId} />
      </div>
    </div>
    { showQrCodeDialog &&
      <QrCodeDialog pageId={pageId}
        onClose={handleQrDialogClose} />
    }
    { showDeleteDialog &&
      <Dialog footer={deletePageFooter}
        onClose={() => isDeleteButtonSpin ? null : setShowDeleteDialog(false)}>
        <DeletePageDialogBody />
      </Dialog>
    }
    <style>
      {`
            .WebPageItem-Link-Upgrade-Container {
                width: 7em;
                padding-top: 5px;
            }
            .WebPageItem {
                height: 10em;
                width: 100%;
                border: 1px solid ${colors.white2};
                background-color: rgb(242, 242, 247, 0.2);
                box-sizing: border-box;
                border-radius: 2px;
                padding: 1em;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                box-shadow: 0 0 1px ${colors.white2};
            }
            .WebPageItem-Link {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                width: 100%;
            }
            .WebPageItem-Actions {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 5px;
            }
            .WebPageItem-Action-Item {
                width: fit-content;
            }
        `}
    </style>
  </div>
}
