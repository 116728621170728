import { css } from "@emotion/css";
import React from "react";
import colors from "../colors";

type Props = {
    progress: number;
}

export const LoadingBar: React.FC<Props> = ({ progress }) => {
  const styles = css`
        position: absolute;
        bottom: 0;
        left: 0;
        width: ${progress}%;
        height: 10px;
        background-color: #00bfff;
        border: 1px solid ${colors.white2};
    `

  return <div className={styles}/>
}
