const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

const specialCharRegex = /[!@#$%^&*(),.?'":{}|<>]/;
const digitRegex = /\d/;
const uppercaseRegex = /[A-Z]/;
const lowercaseRegex = /[a-z]/;

export const validateEmail = (email: string) => {
  if (email.length > 254) {
    return "Email is too long"
  }
  if (email.length < 6) {
    return "Email is too short"
  }
  if (!EMAIL_PATTERN.test(email)) {
    return "Email address is invalid"
  }
  return null
}

export const validatePassword = (password: string) => {
  if (password.length < 6) {
    return "Password must be at least 6 characters long"
  }
  if (password.length > 128) {
    return "Password is too long"
  }

  // const invalidCount =
  // [specialCharRegex, digitRegex, uppercaseRegex, lowercaseRegex]
  //     .filter((regex) => !regex.test(password))

  // if ( invalidCount.length > 2 &&
  //    !lowercaseRegex.test(password) &&
  //     !uppercaseRegex.test(password) ) {
  //   return "A letter"
  // }
  // if (invalidCount.length > 2 && !lowercaseRegex.test(password)) {
  //   return "A lowercase letter"
  // }

  // if (invalidCount.length > 2 && !uppercaseRegex.test(password)) {
  //   return "An uppercase letter"
  // }
  return null
}
