import React from "react";
import colors from "../colors";

type Props = {
    text: string;
    href: string;
}

export const Link: React.FC<Props> = ({ href, text }) => {
  return <>
    <a href={href} className="Link">{ text }</a>
    <style>
      {`
        .Link {
            color: ${colors.white2};
            font-size: 1.2em;
            text-decoration: underline;
        }
      `}
    </style>
  </>
}
