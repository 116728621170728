import React from "react";
import { ViewPageState } from "../../state/ViewPageState";
import { PageItemType } from "../../../edit/dto/PageItemType";
import PhotoView from "../../PhotoView";
import QuoteView from "../../QuoteView";
import { css } from "@emotion/css";

type Props = {
    page: ViewPageState
}

export const TimelinePage: React.FC<Props> = ({ page }) => {
  const styles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 3em;
    padding: 0;
    max-width: 540px;
  `

  return <div className={styles}>
    {page.items.map((item, index) => {
      if (item.type === PageItemType.PHOTO) {
        return <PhotoView size="full"
          image={item.data} />
      } else {
        return <QuoteView text={item.data} />
      }
    })}
  </div>
}
