import PageCreateDto from "../../api/dtos/PageCreateDto";
import { generateRandomString } from "../../common/GenerateHash";
import { TemplateName } from "./TemplateName";

export function getCreatePageDto(templateName: TemplateName): PageCreateDto {
  switch (templateName) {
    case TemplateName.TIMELINE:
      return {
        templateName,
        photos: [
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 0,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-33.jpg?updatedAt=1684147887509",
            pageIndex: 2,
            position: "left",
            key: generateRandomString(),
          },
        ],
        quotes: [
          {
            text: "You are my candle in the darkness..",
            pageIndex: 1,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "You have the key to my heart..",
            pageIndex: 3,
            position: "right",
            key: generateRandomString(),
          },
          {
            text: "I love you ♥️",
            pageIndex: 3,
            position: "right",
            key: generateRandomString(),
          },
        ],
        subdomain: generateSubdomain(),
      }

    case TemplateName.ANNIVERSARY:
      return {
        templateName,
        photos: [
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-22.jpg?updatedAt=1684147887488",
            pageIndex: 0,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-33.jpg?updatedAt=1684147887509",
            pageIndex: 1,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-11.jpg?updatedAt=1684147887534",
            pageIndex: 2,
            position: "left",
            key: generateRandomString(),
          },
          {
            data: "https://ik.imagekit.io/ex5o5cwj51/marco-44_1_.jpeg?updatedAt=1684934797941",
            pageIndex: 3,
            position: "left",
            key: generateRandomString(),
          },
        ],
        quotes: [
          {
            text: "Dec 2022",
            pageIndex: 4,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Feb 2023",
            pageIndex: 5,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "March 2023",
            pageIndex: 6,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "May 2023",
            pageIndex: 7,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "I still smile when I think about our first date," +
            " when we laughed for hours over coffee and shared stories" +
             " about our lives.",
            pageIndex: 8,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "the past six months have been filled with" +
            " countless unforgettable moments with you.",
            pageIndex: 9,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Our first weekend getaway. It was a time of deepening" +
            " our connection, discovering more about each other," +
            " and creating cherished memories together.",
            pageIndex: 10,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: " I can't wait to see what the future holds for us" +
            " as we continue to create a lifetime of" +
            " beautiful memories together.",
            pageIndex: 11,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "6th Month",
            pageIndex: 12,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "Anniversary",
            pageIndex: 13,
            position: "left",
            key: generateRandomString(),
          },
          {
            text: "and it is still counting..",
            pageIndex: 14,
            position: "left",
            key: generateRandomString(),
          },
        ],
        subdomain: generateSubdomain(),
      }
    default:
      throw new Error("Invalid template name")
  }
}

function generateSubdomain(): string {
  return makeid(12)
}

function makeid(length) {
  let result = "";
  const characters =
    "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
