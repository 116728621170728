import React, { useEffect, useState } from "react";

export default function useSubdomain() {
  const [host, setHost] = useState(window.location.host);
  const listenToPopstate = () => {
    const winHost = window.location.host;
    setHost(winHost);
  };
  useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);

  const split = host.split(".");
  if (host.endsWith("lovepage.io")) {
    const hasSubdomain = split.length === 3
    const pageSubdomain = hasSubdomain && split[0];
    return pageSubdomain || null;
  } else if (host.endsWith("localhost:3000")) {
    const hasSubdomain = split.length === 2
    const pageSubdomain = hasSubdomain && split[0];
    return pageSubdomain || null;
  } else {
    return "www"
  }
};
