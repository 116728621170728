import { useContext, useEffect, useState } from "react";
import PageDto from "../../api/dtos/PageDto";
import { getPage } from "../../api/PageAPI";
import { LoadingState } from "../../common/LoadingState";
import { AppContext, AppContextAction } from "../AppContext";
import { ViewState } from "../../common/ViewState";
import { isProduction } from "../../config";


export function usePageFetch(pageId: number): ViewState<PageDto> {
  const { dispatch } = useContext(AppContext);
  const [viewState, setViewState] =
  useState<ViewState<PageDto>>({ state: LoadingState.IDLE })

  async function fetchPage() {
    try {
      setViewState({ state: LoadingState.LOADING })
      const pageDtoResponse = await getPage(false);
      const pageDto = pageDtoResponse.data.find((page) => page.id === pageId);
      if (!pageDto) {
        throw new Error("Page not found");
      }
      setViewState({
        state: LoadingState.LOADED,
        data: pageDto,
      })
      dispatch({
        type: AppContextAction.PAGE_LOAD,
        pages: { state: LoadingState.LOADED, data: pageDtoResponse.data },
      });
    } catch (error) {
      setViewState({ state: LoadingState.FAILED, errorMessage: error })
      if ( !isProduction ) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    fetchPage();
  }, []);

  return viewState
}
