import React, { useEffect, useRef } from "react";
import colors from "../../colors";
import { cx, css } from "@emotion/css";
import "@fontsource-variable/lora";
import { observer } from "mobx-react-lite";

type Props = {
    text: string;
    onChange: (text: string) => void;
    className: string;
    textKey: string
}

const MAX_LENGTH = 165

export const EditableH1: React.FC<Props> =
 observer(({ text, onChange, className, textKey }) => {
   const ref = useRef<HTMLHeadingElement>(null);
   const [initialText, setInitialText] = React.useState<string>(text);
   useEffect(() => {
     setInitialText(text);
   }, [textKey])


   function handleInput(e: React.FormEvent<HTMLHeadingElement>) {
     if (e.currentTarget.innerText.length > MAX_LENGTH) {
       e.currentTarget.innerText =
       e.currentTarget.innerText.slice(0, MAX_LENGTH)
       document.execCommand("selectAll", false, null);
       // collapse selection to the end
       document.getSelection().collapseToEnd();
     }
     onChange(e.currentTarget.innerText);
   }


   function handleFocus() {
     window.getSelection().selectAllChildren(ref.current)
     window.getSelection().collapseToEnd()
   }


   const styles = css`
    color: ${colors.grayDark2};
    padding: 5px;
    margin: 0;
    border: 1px dashed white;
    &:focus {
        border: 1px dashed transparent;
    }
    font-weight: bold;
    font-family: 'Lora Variable', sans-serif;
    word-break: break-word;
    position: relative;
    min-width: 100%;
    line-height: 1;
    -webkit-text-stroke: 3px #fff; /* for Chrome, Safari, and Opera */
    text-stroke: 3px #fff; /* standard syntax */
    text-shadow: 0px 0px 0px rgb(0, 0, 0, 0.1);
    background-clip: text;
    width: fit-content;
    color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    text-shadow: 0 0 5px 0 rgba(0,0,0,0.7);
    font-family: 'Lora Variable', sans-serif;
    font-size: 3.8em;
    font-weight: bold;
    box-sizing: border-box;
    @media (min-width: 768px) {
      font-size: 5em;
    }
    white-space: pre-wrap;
    `;


   return <h1 onFocus={handleFocus}
     ref={ref}
     onInput={handleInput}
     suppressContentEditableWarning={true}
     contentEditable={true}
     className={cx(`EditableH1-${className}`, styles)}>
     {initialText}
   </h1>;
 },
 )
