import React from "react";
import colors from "../colors";
import { css } from "@emotion/css";

type Props = {
  image: string;
  isBorder?: boolean;
  size: "half" | "full"
  isDisabled?: boolean;
}

const PhotoView: React.FC<Props> = ({ image, isBorder = true, size,
  isDisabled = false }) => {
  const style = css`
      width: ${size === "half" ? "49vw" : "90vw"};
      height: ${size === "half" ? "20vh" : "35vh"};
      max-width: ${size === "half" ? "270px" : "540px"};
      background-color: ${colors.white2};
      object-fit: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      border: ${isBorder ? "5px solid " + colors.white2 : "none"};
      box-sizing: border-box;
      @media (min-width: 400px) {
        height: ${size === "half" ? "20vh" : "40vh"};
      }
      @media (min-width: 500px) {
        height: ${size === "half" ? "20vh" : "45vh"};
      }
  `
  return (
    <img
      style={{ filter: isDisabled ? "brightness(30%)" : "none" }}
      className={style}
      src={image}
      alt="A love page photo"/>
  );
};

export default PhotoView;
