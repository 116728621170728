import { css } from "@emotion/css";
import React from "react";
import "@fontsource/barlow-semi-condensed";
import { useTranslation } from "react-i18next";

export const HappyCustomers: React.FC = () => {
  const { t } = useTranslation()
  const styles = css`
        display: flex;
        height: fit-content;
        width: 100%;
        overflow-x: scroll;
        background-color: rgba(255, 255, 255, 0.2);
        font-family: "Barlow Semi Condensed",sans-serif;
        font-size: 1.5em;
        padding: 1em 20px;
        box-sizing: border-box;
        text-align: left;
        word-break: none;
        line-height: 1.5em;
        align-items: start;
        justify-content: start;
        gap: 1em;
        flex-direction: column;
        .Happy-Customers-Photo {
            width: 70px;
            height: 70px;
        }
        .Happy-Customer-Info {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 1em;
            width: 100%;
        }
        .Happy-Customer-Name > p {
            margin: 0;
            font-size: 0.8em;
        }
    `

  return <div className={styles}>
    {t("testimonial-1")}
    <div className="Happy-Customer-Info">
      <img src="https://ik.imagekit.io/ex5o5cwj51/customer-1.jpeg?updatedAt=1686067905011"
        className="Happy-Customers-Photo"
      />
      <div className="Happy-Customer-Name">
        <b>Oliver Seymour</b>
        <p>Sheridan, Wyoming</p>
      </div>
    </div>
  </div>
}

