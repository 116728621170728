import React from "react";
import colors from "../colors";
import TextArea from "../common/TextArea";
import { ItemActions } from "./ItemActions";
import { css, cx } from "@emotion/css";

type Props = {
  text: string
  onChange: (index: number, val: string) => void
  onUpClick: (index: number) => void
  onDownClick: (index: number) => void
  onDelete: (index: number) => void
  index: number
  autoFocus: boolean
  isDndTarget: boolean
}

const QuoteItem: React.FC<Props> = ({ text, onChange, onDelete,
  onUpClick, onDownClick,
  index, autoFocus, isDndTarget }) => {
  const [isFocus, setFocus] = React.useState(autoFocus);

  // focus by click
  const [isFocused, setFocused] = React.useState(false);
  function handleChange(text) {
    onChange(index, text)
  }

  function handleDeleteClick() {
    onDelete(index)
  }

  function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {
    console.log("handleEditClick", event);

    event.stopPropagation()
    setFocus(true)
    const element = document
        .querySelectorAll(".EditPage-Image,.EditPage-Quote")[index]
    element.querySelector("textarea").focus()
  }

  const focusStyle = css`
    & {
      border-top: 1px dashed white;
      border-bottom: 1px dashed white;
      border-left: 1px dashed white;
      border-right: 1px dashed white;
      box-sizing: border-box;
    }
    &:focus ~ .QuotesItem-Actions {
      opacity: 1;
    }
    &:focus {
      border-right: 3px solid #007cff;
      border-top: 3px solid #007cff;
      border-bottom: 3px solid #007cff;
      border-left: 3px solid #007cff;
    }
  `

  return (
    <li style={{ border: isDndTarget ? "3px dotted aqua" : "none" }}
      onClick={() => setFocused((v) => !v)}
      className="QuotesItem">
      <TextArea testId="quote-item-text-area"
        className={cx("QuotesItem-Container", focusStyle)}
        onBlur={() => setFocus(false)}
        defaultText={text}
        onChange={handleChange}
        autoFocus={isFocus}
        placeholder={!text ?
          "Write text here" : ""} />

      <div className="QuotesItem-Actions">
        <ItemActions
          isEditHidden={true}
          onUpClick={() => onUpClick(index)}
          onDownClick={() => onDownClick(index)}
          onDeleteClick={handleDeleteClick}
          onEditClick={handleEditClick} />
      </div>

      <style>
        {`
            .QuotesItem textarea {
              padding: 0;
              width: 100%;
              min-height: 2vh;
              cursor: text;
              background: transparent;
              color: ${colors.white2};
              font-size: 1.8em;
              font-weight: 700;
              padding: 10px 10px;
              font-family: 'Lora Variable', sans-serif;;
              box-sizing: border-box;
            }
            .QuotesItem textarea::-webkit-scrollbar {
              display: none;
            }

            .QuotesItem textarea:focus::placeholder {
              color: transparent;
            }

            .QuotesItem {
              display: flex;
              align-items: center;
              flex-direction: column;
              list-style: none;
              border: 0;
              width: 100%;
              box-sizing: border-box;
            }
            .QuotesItem > textarea {
              cursor: pointer;
              font-style: italic;
              font-family: 'Lora Variable', sans-serif;
              text-shadow: 1px 1px 2px black;
            }
            .QuotesItem > textarea:focus {
              cursor: text;
            }
            .QuotesItem-Actions {
              padding: 0.5em 0 0 0;
              display: flex;
              opacity: 0;
              justify-content: center;
              align-items: center;
              width: 100%;
            }
            .QuotesItem-Container {
              position: relative;
              display: flex;
              width: 100%;
            }
            .QuotesItem-Drag-Button {
              position: absolute;
              right: -18px;
              top: calc(50% - 20px);
              padding-left: 1em;
              cursor: move;
            }
          `}
      </style>
    </li>
  );
};

export default React.memo(QuoteItem);
