import React from "react";
import { Spinner } from "./Spinner";

export const PageBlockSpinner: React.FC = () => {
  return <div className="PageBlockSpinner">
    <Spinner />
    <style>
      {`
            .PageBlockSpinner {
                position: fixed;
                z-index: 5;
                height: 100%;
                width: 100%;
                background-clip: border-box;
                background-color: rgba(0, 0, 0, 0.5);
                top: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            `}
    </style>
  </div>
}
