import React, { useEffect, useState } from "react";
import { IoCloseSharp, IoInfiniteOutline } from "react-icons/io5";
import { observer } from "mobx-react-lite";
import { css, cx } from "@emotion/css";
import colors from "../colors";
import { Plan } from "./Plan";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import { PlanType } from "../../api/dtos/PlanType";
import { FreePlan } from "./FreePlan";

type Props = {
    onClose?: () => void;
    isAuth: boolean;
    onlyPremiumPlan?: boolean;
}

export const UpgradeModal: React.FC<Props> = observer(({
  onClose,
  isAuth,
  onlyPremiumPlan,
}) => {
  const [selectedPlanType, setSelectedPlanIndex] =
   useState<PlanType>(onlyPremiumPlan ? PlanType.ANNUAL : PlanType.FREE);

  const navigate = useNavigate();

  const header = isAuth ? "Upgrade to Premium" : "Sign Up to Premium";

  if ( !onClose ) {
    onClose = () => {
      navigate(-1)
    }
  }

  function handleClose() {
    localStorage.removeItem("selectedPremium")
    localStorage.removeItem("isUpgradeBannerClicked")
    onClose()
  }

  const price = selectedPlanType === PlanType.ANNUAL ? "0." : "9.";
  const fraction = "99"
  const calendarText = "one time payment";

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);


  const contentStyle = css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    color: ${colors.grayDark2};
    gap: 1em;
    .Upgrade-Options {
        display: flex;
        flex-direction: row;
        padding: 1em;
        padding-top: 0;
        width: 100%;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .Upgrade-Option {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: content-fit;
        height: 20px;
        padding: 10px 5px;
        cursor: pointer;
        border-radius: 0px;
        border: 1px solid ${colors.gray};
    }
    .Upgrade-Option-Title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 14px;
    }
    .Upgrade-Option > input {
        margin: 0;
        margin-right: 5px;
    }
    .Upgrade-Option-Active {
        border: 1.5px solid ${colors.lightBlue};
    }
    
    .Main-Header {
        color: ${colors.grayDark2};
        margin: 0;
        margin-bottom: 10px;
    }
  `

  function handleUpgradeClick() {

  }

  return <div className="UpgradeModal">
    <div className="UpgradeModal-Close-Btn">
      <IoCloseSharp color={colors.gray} onClick={handleClose}
        className="Close-Icon" size={40} />
    </div>
    <div className={contentStyle}>
      <h1 className="Main-Header">{header}</h1>
      <p className="Money-Back-Guarantee">
        <MdVerified size={20} />7 days money back guarantee
      </p>
      <div className="Upgrade-Options">
        {!onlyPremiumPlan === true &&
         <label className={selectedPlanType === PlanType.FREE ?
         cx("Upgrade-Option", "Upgrade-Option-Active") : "Upgrade-Option"}
         onClick={() => setSelectedPlanIndex(PlanType.FREE)}>
           <input type="radio" value={0}
             checked={selectedPlanType === PlanType.FREE} />
           <div className="Upgrade-Option-Title Annual">
             <b>Free</b> 🔥
           </div>
         </label>
        }
        <label className={selectedPlanType === PlanType.ANNUAL ?
         cx("Upgrade-Option", "Upgrade-Option-Active") : "Upgrade-Option"}
        onClick={() => setSelectedPlanIndex(PlanType.ANNUAL)}>
          <input type="radio" value={1}
            checked={selectedPlanType === PlanType.ANNUAL} />
          <div className="Upgrade-Option-Title Annual">
            <b>Annual</b><span>(Save 95%)</span>
          </div>
        </label>
        <label className={selectedPlanType === PlanType.LIFETIME ?
         cx("Upgrade-Option", "Upgrade-Option-Active") : "Upgrade-Option"}
        onClick={() => setSelectedPlanIndex(PlanType.LIFETIME)}>
          <input type="radio" value={PlanType.LIFETIME}
            checked={selectedPlanType === PlanType.LIFETIME} />
          <div className="Upgrade-Option-Title">
            <b>Lifetime</b> <IoInfiniteOutline size={20} />
          </div>
        </label>
      </div>
      {selectedPlanType === PlanType.FREE &&
       <FreePlan isAuth={isAuth} onUpgradeClick={handleUpgradeClick}/>
      }
      {selectedPlanType !== PlanType.FREE &&
      <Plan
        planType={selectedPlanType}
        onUpgradeClick={handleUpgradeClick}
        key={calendarText}
        price={price}
        fraction={fraction}
        calendarText={calendarText}
        isAuth={isAuth}
      />
      }
    </div>
    <div className="UpgradeModal-Footer">
      <FaLock size={15} />Pay securely with Stripe
    </div>
    <style>
      {`    .Money-Back-Guarantee {
              color: ${colors.grayDark2};
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5px;
              font-size: 14px;
            }
            .UpgradeModal-Footer {
              margin-top: 20px;
              width: 80%;
              border-top: 1px solid #C8C8C8;
              color: ${colors.grayDark};
              padding: 15px 5px;
              display: flex;
              justify-content: center;
              gap: 5px;
              align-items: center;
              font-size: 1em;
            }
            .UpgradeModal {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 100%;
                z-index: 5;
                top:0;
                left: 0;
                position: fixed;
                background-color: ${colors.white2};
                box-sizing: border-box;
                overflow: scroll;
              }
              .UpgradeModal-Close-Btn {
                width: 100%;
                height: fit-content;
                padding: 0 10px;
                z-index: 2;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
              }
              .Close-Icon > path {
                stroke: white;
              }
              .Close-Icon > fill {
                color: white;
              }
              .Close-Icon {
                cursor: pointer;
              }
              .Upgrade-Option-Title > span {
                white-space: nowrap;
              }
            `}
    </style>
  </div>;
})
