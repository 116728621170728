import React from "react";

export const DeleteAccountDialogBody: React.FC = () => {
  return <div className="DeleteAccountDialogBody">
    <div className="DeleteAccountDialogBody-Title">
        Are you sure you want to delete your account?
      <br/>
      <p>
        This action cannot be undone.
      </p>
    </div>
    <style>
      {`
            .DeleteAccountDialogBody-Title {
                font-size: 1.2em;
                padding: 2em 1em 1em 1em;
            }
            .DeleteAccountDialogBody { 
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        `}
    </style>
  </div>
}
