import { css } from "@emotion/css";
import React, { useContext, useEffect, useState } from "react";
import Input from "../common/Input";
import ErrorMessage from "../ErrorMessage";
import TextArea from "../common/TextArea";
import DefaultButton from "../common/DefaultButton";
import { validateEmail } from "../auth/Validator.util";
import { sendContactForm } from "../../api/SupportApi";
import colors from "../colors";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";

export const SupportForm: React.FC = () => {
  const { state } = useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isSendBtnSpin, setIsSendBtnSpin] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    if (state.userDto.state === LoadingState.LOADED) {
      setName(state.userDto.data?.name ?? "");
      if (state.userDto.data?.email !== state.userDto.data?.deviceId) {
        setEmail(state.userDto.data?.email ?? "");
      }
    }
  }, [state.userDto.state]);

  function handleMessageChange(val: string) {
    setServerErrorMessage("")
    setIsFormSent(false);
    setMessage(val);
  }
  function handleEmailChange(val: string) {
    setEmailError("");
    setIsFormSent(false);
    setServerErrorMessage("")
    setEmail(val);
  }
  function handleNameChange(val: string) {
    setServerErrorMessage("")
    setIsFormSent(false);
    setName(val);
  }
  function resetContactForm() {
    Array.from(document.querySelectorAll(".Support-Form input,textarea"))
        .forEach((i: HTMLInputElement | HTMLTextAreaElement) => i.value= "")
  }
  async function handleSubmitClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const emailHasError = validateEmail(email)
    const anyEmptyField = !name || !email || !message
    if ( emailHasError ) {
      setEmailError(emailHasError)
    }
    if ( anyEmptyField ) {
      setShowEmptyError(true);
    }
    if ( emailHasError || anyEmptyField ) {
      return;
    }
    try {
      setIsSendBtnSpin(true);
      await sendContactForm(
          { name, email, message: "via:support-form <br/><br/>" + message })
      resetContactForm()
      setIsFormSent(true);
    } catch (error) {
      setServerErrorMessage(error?.response?.data?.message ||
        "Something went wrong, we are working on it");
      console.error(error);
      return;
    } finally {
      setIsSendBtnSpin(false);
      setShowEmptyError(false)
    }
  }


  const styles = css`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    color: ${colors.white2};
    .ContactUs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: ${colors.white2};
        padding-top: 2em;
        padding-bottom: 4em;
    }
    .ContactUs-Channels {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 1em;
        box-sizing: border-box;
        padding: 4em 1em 4em 1em;
    }
    .ContactUs-Channels label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2em;
        width: 100%;
        height: 100%;
    }
    .Addressbook-Label-Text {
        min-width: 90px;
        font-size: 1.2em;
        display: flex;
        justify-content: start;
        font-weight: 600;
    }
    .Addressbook-Value {
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .Support-Form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 1em;
        padding: 1em;
        box-sizing: border-box;
    }
    .Support-Form label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2em;
        width: 100%;
    }
    .Contact-Input {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 500px;
        color: ${colors.white2};
    }
    .Contact-Input textarea {
        font-size: 1.2em;
    }
    .Form-Label-Text {
        width: 90px;
        font-size: 1.2em;
        font-weight: 600;
    }
    .Support-Form-Submit-Button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 90px;
    }
    .Support-Form-error {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 1em 1em 1em;
    }
    .Support-Form-Header {
        margin-left: 90px;
        margin-bottom: 5px;
    }
    .Contact-Email-Error {
        display: flex;
        justify-content: start;
        align-items: center;
        width: 100%;
    }
    .Support-Form-success {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 1em 1em 1em;
    }
    @media (max-width: 750px) {
        .Support-Form label {
            flex-direction: column;
            gap: 1em;
        }
        .Form-Label-Text {
            width: 100%;
            justify-content: start;
        }
        .Support-Form-Submit-Button {
            margin-left: 0px;
        }
        .Support-Form-Header {
            margin-left: 10px;
        }
        .ContactUs-Channels {
          padding: 1em;
        }
    }
  `;
  return (<div className={styles}>
    <h2 className="Support-Form-Header">Submit Support Ticket</h2>
    <form className="Support-Form">
      <label>
        <span className="Form-Label-Text">Name:</span>
        <div className="Contact-Input">
          <Input textAlign="left"
            isError={showEmptyError && !name}
            defaultValue={name} onChange={handleNameChange} />
        </div>
      </label>
      <label>
        <span className="Form-Label-Text">Your Email:</span>
        <div className="Contact-Input">
          <Input className="Contact-Email" textAlign="left"
            isError={showEmptyError && !email}
            defaultValue={email} onChange={handleEmailChange} />
          {emailError && <div className="Contact-Email-Error">
            <ErrorMessage message={emailError} />
          </div> }
        </div>
      </label>
      <label>
        <span className="Form-Label-Text">Message:</span>
        <div className="Contact-Input">
          <TextArea
            defaultText={message}
            onBlur={() => null}
            onChange={handleMessageChange}
            placeholder=""
            minHeight="8vh"
            isError={showEmptyError && !message}
          />
        </div>
      </label>
      <div className="Support-Form-Submit-Button">
        {showEmptyError && (!name || !email || !message) &&
      <div className="Support-Form-error">
        <ErrorMessage message="❌ Please fill all the fields" />
      </div>
        }
        { serverErrorMessage &&
        <ErrorMessage message={serverErrorMessage} />
        }
        {!serverErrorMessage && !emailError &&
       isFormSent && <div className="Support-Form-success">
          <span>✅ You contact request has been received.
          We will get back to you shortly.</span>
        </div>}
        <DefaultButton isSpin={isSendBtnSpin}
          text="Submit" onClick={handleSubmitClick} />
      </div>
    </form>
  </div>)
}
