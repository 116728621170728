import { css } from "@emotion/css";
import React from "react";

type RibbonProps = {
    color: string;
    children: React.ReactNode;
}
export const Ribbon: React.FC<RibbonProps> = ({ children, color }) => {
  const style = css`
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 10px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 0 10px var(--f) calc(10px + var(--r));
    clip-path: 
    polygon(0 0,100% 0,100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),
        0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: ${color};
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    `

  return <div className={style}>
    {children}
  </div>
}
