import React from "react";
import Dialog from "../dialog/Dialog";
import { GrDownload } from "react-icons/gr";
import DefaultButton from "../common/DefaultButton";
import colors from "../colors";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import { usePageUrl } from "./usePageUrl";

type Props = {
    onClose: () => void;
    pageId: number;
}

export const QrCodeDialog: React.FC<Props> = ({ onClose, pageId }) => {
  const pageUrl = usePageUrl(pageId)
  const downloadIcon = <>
    <GrDownload size={20} className="Download-Icon" />
    <style>
      {`
            .Download-Icon path {
                stroke: ${colors.white};
            }
        `}
    </style>
  </>

  function exportCanvasAsPNG() {
    const canvasElement: HTMLCanvasElement =
     document.querySelector(".QrCodeDialog-Body-Code-Canvas");

    const MIME_TYPE = "image/png";

    const imgURL = canvasElement.toDataURL(MIME_TYPE);


    const dlLink = document.createElement("a");
    dlLink.download = "lovepage_qr.png";
    dlLink.href = "data:image/png;" + imgURL;

    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  }

  function handleDownloadClick(event:
    React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    exportCanvasAsPNG()
    onClose()
  }

  const footer = <div className="QrCodeDialog-Footer">
    <div className="QrCodeDialog-Download-Btn">
      <DefaultButton text="Download" icon={downloadIcon}
        onClick={handleDownloadClick}
      />
    </div>
    <style>
      {`
        .QrCodeDialog-Footer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 1em;
            height: 70px;
        }
        .QrCodeDialog-Download-Btn {
            width: 10em;
        }
        @media screen and (max-width: 500px) {
            .QrCodeDialog-Footer {
                flex-direction: column;
                height: 50px;
            }
        }
    `}
    </style>
  </div>
  return <Dialog onClose={onClose} footer={footer} >
    <div className="QrCodeDialog-Body">
      <QRCodeSVG className="QrCodeDialog-Body-Code" value={pageUrl} />
      <QRCodeCanvas className="QrCodeDialog-Body-Code-Canvas"
        value={pageUrl} />

      <style>
        {`
                .QrCodeDialog-Body {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 200px;
                }
                .QrCodeDialog-Body-Code {
                    border: 1px solid ${colors.white2};
                }
                .QrCodeDialog-Body-Code-Canvas {
                    display: none;
                }
            `}
      </style>
    </div>
  </Dialog>;
};


