import React from "react";

type Props = {
    message?: string;
}
const ErrorMessage: React.FC<Props> =
({ message = "Something went wrong" }) => {
  return <>
    <div className="Error">{ message }</div>
    <style>
      {`
            .Error {
                color: red;
                padding: 5px;
                display:flex;
                justify-content:center;
            }
        `}
    </style>
  </>;
};

export default ErrorMessage;
