import React, { useEffect } from "react";
import colors from "../colors";
import { TemplateView } from "./TemplateView";
import { TemplateViewActions } from "./TemplateViewActions";
import { TemplateName } from "./TemplateName";

export const Templates = () => {
  const [selectedTemplateIndex, setSelectedTemplateIndex] = React.useState(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function handleSelectClick(index: number) {
    selectedTemplateIndex === index ? setTimeout(() => {
      setSelectedTemplateIndex(null)
    }, 50) :
      setSelectedTemplateIndex(index)
  }

  function handleBlur(index: number) {
    setTimeout(() => {
      setSelectedTemplateIndex((v) => v === index ? null : v)
    }, 100);
  }
  return <div className="Templates">
    <div className="Templates-Title">
      <h1 className="Templates-Title-First">Choose a template</h1>
      <h2 className="Templates-Title-Second">
        to start creating your website.</h2>
    </div>
    <div className="Templates-List">
      <div
        className="Template-Item">
        <div className="Template-Item-Title">
          <h2 className="Template-Item-Title-Text">
            Anniversary
          </h2>
        </div>
        <div className="Template-Item-View"
          tabIndex={0} onClick={() => handleSelectClick(0)}
          onBlur={() => handleBlur(0)}>
          <TemplateView templateName={TemplateName.ANNIVERSARY}
            showOverlay={selectedTemplateIndex === 0} />
        </div>
        <div className="Template-Item-Actions">
          <TemplateViewActions alignment="vertical"
            templateName={TemplateName.ANNIVERSARY}
            className="Templates-View-Actions-1" />
        </div>
      </div>
      <div
        className="Template-Item">
        <div className="Template-Item-Title">
          <h2 className="Template-Item-Title-Text">
            Timeline romance
          </h2>
        </div>
        <div onBlur={() => handleBlur(1)} className="Template-Item-View"
          tabIndex={1} onClick={() => handleSelectClick(1)}>
          <TemplateView templateName={TemplateName.TIMELINE}
            showOverlay={selectedTemplateIndex === 1} />
        </div>
        <div className="Template-Item-Actions">
          <TemplateViewActions alignment="vertical"
            templateName={TemplateName.TIMELINE}
            className="Templates-View-Actions-0" />
        </div>
      </div>
    </div>
    <style>
      {`
        .Templates {
            padding-bottom: 8em;
            color: ${colors.white2};
            min-height: 100vh;
            max-width: 750px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .Template-Item-Actions {
          margin-top: 1.5em;
        }
        .Templates-List {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 1em;
            padding: 1em 0.5em;
            box-sizing: border-box;
            flex-wrap: wrap;
        }
        .Template-Item {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
            padding: 1em;
            border-radius: 5px;
        }
        .Template-Item-View {
          cursor: pointer;
          width: fit-content;
          max-width: 450px;
        }
        .Templates-Title {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            padding: 1em 1em 0 1em;
            box-sizing: border-box;
            width: 100%;
        }
        .Templates-Title-First,.Templates-Title-Second {
            width: 0%;
            text-align: left;
            box-sizing: border-box;
            line-height: 1.5em;
            animation: typing 0.5s steps(20, end) forwards;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            width: 0;
        }
        .Templates-Title-Second {
            animation-delay: 0.5s;
            margin-top: 5px;
            margin-bottom: 0.5em;
        }
        .Templates-Title-First {
            margin-bottom: 0;
        }
        @keyframes typing {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }
    `}
    </style>
  </div>
}
