import React from "react";
import ProfileBox from "../dashboard/ProfileBox";
import Logo from "./Logo";
import colors from "../colors";

const Header: React.FC = () => {
  return <div className="Header">
    <div className="Header-Logo">
      <Logo />
    </div>
    <div className="ProfileBox">
      <ProfileBox/>
    </div>
    <div className="Header-Link-Group">
      <a href="/contact-us" className="Header-Link">Contact Us</a>
      <a href="/pricing" className="Header-Link">Pricing</a>
      <a className="Header-Link" href="/sign-in">
        Sign In
      </a>
    </div>
    <style>
      {`
        .Header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          width: 100%;
          box-sizing: border-box;
          font-family: "Source Sans Pro", sans-serif;
        }
        .ProfileBox {
          display: flex;
          justify-content: right;
        }
        .Header-Link {
          color: ${colors.white2};
          font-size: 1.2em;
          text-decoration: underline;
        }
        .Header-Link-Group {
          display: flex;
          gap: 1em;
        }        
        @media only screen and (min-width: 750px) {
          .ProfileBox {
            padding: 2em;
          }
          .Header-Link-Group {
            gap: 2em;
            padding-right: 2em;
          }
        }
      `}
    </style>
  </div>;
};

export default Header;
