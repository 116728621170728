import { useContext } from "react";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import { PlanType } from "../../api/dtos/PlanType";

type ActivePlanDto = {
  plan: PlanType
}

export function useActivePlan(): ActivePlanDto {
  const { state } = useContext(AppContext)
  const user = state.userDto.state === LoadingState.LOADED ?
  state.userDto.data : null

  return {
    plan: user?.plan ?? null,
  }
}
