import axios from "axios";

export async function sendSubscribeEmail(email: string): Promise<void> {
  await axios.post("/api/subscribe", { email });
}

export async function unsubscribeFromNewsletter(email: string): Promise<void> {
  await axios.post("/api/subscribe/delete", { email });
}

