import React, { useEffect } from "react";
import PageDto from "../../api/dtos/PageDto";
import { LoadingState } from "../../common/LoadingState";
import { ViewState } from "../../common/ViewState";
import { usePageViewFetch } from "./usePageViewFetch";
import { Spinner } from "../common/Spinner";
import { SharePageButton } from "./SharePageButton";
import { ViewPageState } from "./state/ViewPageState";
import { PageItemType } from "../edit/dto/PageItemType";
import { generateRandomString } from "../../common/GenerateHash";
import { TemplateName } from "../create/TemplateName";
import { AnniversaryPage } from "./theme/AnniversaryPage";
import { TimelinePage } from "./theme/timeline/TimelinePage";
import { observer } from "mobx-react-lite";

type Props = {
    subdomain: string
}

const viewPageState = new ViewPageState();

const Page: React.FC<Props> = observer(({ subdomain }) => {
  const pageViewState: ViewState<PageDto> = usePageViewFetch(subdomain);
  const isSignedIn = pageViewState.state === LoadingState.LOADED

  useEffect(() => {
    if (pageViewState.state === LoadingState.LOADED) {
      viewPageState.setItems(
          [
            ...pageViewState.data.photos,
            ...pageViewState.data.quotes,
          ].sort((a, b) => a.pageIndex - b.pageIndex)
              .map((item) => {
                if ("data" in item) {
                  return {
                    type: PageItemType.PHOTO,
                    data: item.data,
                    key: generateRandomString(),
                  }
                } else {
                  return {
                    type: PageItemType.QUOTE,
                    data: item.text,
                    key: generateRandomString(),
                  }
                }
              }),
      )
      viewPageState.setIsExpired(pageViewState.data.isExpired)
      viewPageState.setHasActivePlan(pageViewState.data.hasActivePlan)
      viewPageState.setTemplateName(pageViewState.data.templateName)
    }
  }, [pageViewState.state])

  const isShareAvailable = !!navigator.share

  switch (pageViewState.state) {
    case LoadingState.IDLE:
    case LoadingState.LOADING:
      return (
        <Spinner />
      )
    case LoadingState.LOADED:
      return (
        <div className="Page">
          {viewPageState.templateName === TemplateName.ANNIVERSARY &&
          <AnniversaryPage page={viewPageState} />}
          {viewPageState.templateName === TemplateName.TIMELINE &&
          <TimelinePage page={viewPageState} />}
          {isShareAvailable && <div className="Page-Share-Button">
            <SharePageButton />
          </div>}
          <style>
            {`
              .Page {
                display: flex;
                flex-direction: column;
                padding: 4em 0 5em 0;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
              }
              .Page-Share-Button {
                display: flex;
                justify-content: center;
                padding: 5em 1em 1em 0;
              }
            `}
          </style>
        </div>
      )
    case LoadingState.FAILED:
      return (
        <div className="Page-Error">
          <h4>This page doesn&apos;t exist :(</h4>
          <style>
            {`
          .Page-Error {
            display: flex;
            justify-content: center;
            color: white;
          }
        `}
          </style>
        </div>
      )
    default:
      return <div></div>
  }
})

export default Page;
