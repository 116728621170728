import { action, makeAutoObservable, observable } from "mobx";
import { PageItem } from "../dto/PageItem";
import { PageItemType } from "../dto/PageItemType";
import { generateRandomString } from "../../../common/GenerateHash";
import { TemplateName } from "../../create/TemplateName";

export class EditPageState {
  items: PageItem[] = [];
  templateName: TemplateName = null

  constructor() {
    makeAutoObservable(this, {
      items: observable,
      templateName: observable,
      updateItem: action,
      setTemplateName: action,
      setItems: action,
      deleteItem: action,
      addPhotoItem: action,
      addQuoteItem: action,
      replaceItems: action,
    });
  }

  get emptyItems() {
    return this.items.filter((item) => item.data === "");
  }

  updateItem(pageIndex: number, val: string) {
    this.items[pageIndex].data = val;
  }

  setTemplateName(templateName: TemplateName) {
    this.templateName = templateName;
  }

  setItems(items: PageItem[]) {
    this.items = items;
  }

  deleteItem(pageIndex: number) {
    this.items.splice(pageIndex, 1);
  }

  addPhotoItem() {
    this.items.push({
      data: "",
      type: PageItemType.PHOTO,
      key: generateRandomString(),
    });
  }

  addQuoteItem() {
    this.items.push({
      data: "",
      type: PageItemType.QUOTE,
      key: generateRandomString(),
    });
  }

  replaceItems(fromIndex: number, toIndex: number) {
    const fromItem = this.items[fromIndex];
    const toItem = this.items[toIndex];
    this.items[fromIndex] = toItem;
    this.items[toIndex] = fromItem;
  }
}
