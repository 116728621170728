const maxLen = 20
export function hash(val: string) {
  val = removeSpaces(val)
  let sub = val.length > maxLen ? val = val.slice(0, maxLen) : val = val
  sub = sub + generateRandomString(maxLen - sub.length)
  return sub
}

export function generateRandomString(len = 10) {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < len; i++) {
    randomString += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return randomString;
}

function removeSpaces(str) {
  return str.replace(/\s/g, "");
}
