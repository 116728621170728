import React from "react";
import colors from "../colors";
import { RiDeleteBinLine } from "react-icons/ri";

type Props = {
  onClick: () => void;
  size?: "small" | "large";
  isDisabled?: boolean;
}

const RemoveButtonCircle: React.FC<Props> = ({ onClick, size,
  isDisabled = false }) => {
  const iconSize = size === "small" ? 20 : 30;
  // @ts-ignore
  return <button tabIndex={0}
    disabled={isDisabled} className="RemoveButtonCircle-Container">
    <RiDeleteBinLine
      onClick={onClick} className="RemoveButtonCircle" size={iconSize} />
    <style>
      {`
        .RemoveButtonCircle-Container {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${colors.red};
          border-radius: 50%;
          padding: 4px;
          cursor: pointer;
          border: 1px solid ${colors.white2};
        }
        .RemoveButtonCircle {
          color: ${colors.white2};
        }
        .RemoveButtonCircle-Container:hover {
          -webkit-filter: brightness(70%);
        }
        .RemoveButtonCircle-Container:disabled {
          filter: brightness(30%);
          --webkit-filter: brightness(30%);
          border: none;
          cursor: not-allowed;
        }
      `}
    </style>
  </button>;
};

export default RemoveButtonCircle;
