import React from "react";
import colors from "../colors";

export const FAQ = () => {
  const [expanded, setExpanded] = React.useState([]);
  function isExpanded(index: number) {
    return expanded.includes(index);
  }

  function toggle(index: number) {
    if (isExpanded(index)) {
      setExpanded(expanded.filter((i) => i !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  }


  return <div className="FAQ">
    <h3 className="FAQ-Title">Frequently Asked Questions</h3>
    <div className="FAQ-Question">
      <button onClick={() => toggle(0)} className="FAQ-Question-Title">
      How long does it take to create a website?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(0) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(0) ? "unset": "0px",
        padding: isExpanded(0) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        It takes 3 minutes to create a website. No coding skills required.
      </div>
    </div>
    <div className="FAQ-Question">
      <button onClick={() => toggle(1)} className="FAQ-Question-Title">
        How much does it cost?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(1) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(1) ? "unset": "0px",
        padding: isExpanded(1) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        Creating a website with free templates is absolutely free.
        <br/>However, if you want to take your website to the next level,
        our premium templates are available for a small fee.
      </div>
    </div>
    <div className="FAQ-Question">
      <button onClick={() => toggle(2)} className="FAQ-Question-Title">
        Can I create multiple websites?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(2) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(2) ? "unset": "0px",
        padding: isExpanded(2) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        Yes, you can create as many websites as you want.
        <br/>You don&apos;t need to buy a new plan for each website.
      </div>
    </div>
    <div className="FAQ-Question">
      <button onClick={() => toggle(3)} className="FAQ-Question-Title">
        Can I cancel my subscription?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(3) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(3) ? "unset": "0px",
        padding: isExpanded(3) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        You can cancel anytime. You will still be able to use your website
        until it expires. You will not be charged again.
      </div>
    </div>
    <div className="FAQ-Question">
      <button onClick={() => toggle(4)} className="FAQ-Question-Title">
        Can I get a refund?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(4) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(4) ? "unset": "0px",
        padding: isExpanded(4) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        Yes, you can get a refund within 7 days of your purchase.
        You don&apos;t need a reason to get a refund.
      </div>
    </div>
    <div className="FAQ-Question">
      <button onClick={() => toggle(5)} className="FAQ-Question-Title">
        Can I edit my website after purchase?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(5) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(5) ? "unset": "0px",
        padding: isExpanded(5) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        Yes, you can change and edit your website(s) as much as you want.
      </div>
    </div>
    <div className="FAQ-Question">
      <button onClick={() => toggle(6)} className="FAQ-Question-Title">
        Can I delete my website?
        <span className="FAQ-Question-Title-Caret">
          { isExpanded(6) ? "-" : "+" }
        </span>
      </button>
      <div style={{ maxHeight: isExpanded(6) ? "unset": "0px",
        padding: isExpanded(6) ? "1em" : "0px" }}
      className="FAQ-Question-Answer">
        Yes, you can delete your websites anytime with a single click.
        <br/>Your website will be deleted permanently and it won&apos;t be
        shown to anyone.
      </div>
    </div>
    <style>
      {`
            .FAQ {
                margin-top: 5em;
                margin-bottom: 2em;
                width: 70%;
            }
            .FAQ-Title {
                text-align: center;
                margin: 0;
                margin-bottom: 1em;
                color: ${colors.white2};
            }
            .FAQ-Question {
                padding: 0;
                border-bottom: 1px solid ${colors.white2};
            }
            .FAQ-Question:nth-child(8) {
                border-bottom: none;
            }
            .FAQ-Question-Title {
                font-weight: bold;
                cursor: pointer;
                width: 100%;
                text-align: left;
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.2em;
                color: ${colors.white2};
                padding: 1em;
                transition: 0.4s;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .FAQ-Question-Title:hover, .FAQ-Question-Title:active {
                background-color:  rgba(255, 255, 255, 0.2);
            }
            .FAQ-Question-Title-Caret {
                font-size: 1.2em;
            }
            .FAQ-Question-Answer {
                transition: max-height 0.2s ease-out;
                overflow: hidden;
                font-size: 1em;
                color: ${colors.white2};
                padding: 0 1em;
                width: 100%;
                box-sizing: border-box;
                line-height: 1.5em;
            }
            @media only screen and (max-width: 750px) {
                .FAQ {
                  width: 100%;
                }
            }
        `}
    </style>
  </div>
}
