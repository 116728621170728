import React, { useEffect, useState } from "react";
import { EditPageItem } from "../EditPageItem";
import { PageItem } from "../dto/PageItem";
import { EditPageState } from "../state/EditPageState";
import { observer } from "mobx-react-lite";
import Tour from "reactour";
import { css } from "@emotion/css";
import colors from "../../colors";

type Props = {
    state: EditPageState;
    isWarnEmptyFields: boolean;
}

export const TimelineTemplate: React.FC<Props> = observer(({
  state,
  isWarnEmptyFields,
}) => {
  const [dragging, setDragging] = useState(null);
  const [dropping, setDropping] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    !localStorage.getItem("isTourOpen") && setIsTourOpen(true);
    if ( isWarnEmptyFields ) {
      const item = state.items.find((item) => !item.data)
      if ( !item ) {
        return
      }
      const index = state.items.indexOf(item)
      scrollToIndex(index)
    }
  }, [isWarnEmptyFields])


  const renderItems = () => {
    return state.items.map(renderItem)
  }


  function handleQuoteChange(index: number, quote: string) {
    state.updateItem(index, quote)
    setFocusedIndex(index)
  }

  function handleImageChange(index: number, photo: string) {
    state.updateItem(index, photo)
  }

  function handleDeleteItem(index: number) {
    state.deleteItem(index)
  }

  function handleDragStart(event: React.DragEvent<HTMLDivElement>,
      index: number) {
    setDragging(index)
    event.dataTransfer.setData("text/plain", index.toString())
    event.dataTransfer.effectAllowed = "move"
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>,
      index: number) {
    event.preventDefault()
    setDropping(index)
    event.dataTransfer.dropEffect = "move"
  }

  function handleDragEnd(event: React.DragEvent<HTMLDivElement>,
      index: number) {
    setDragging(null)
    setDropping(null)
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>,
      dropIndex: number) {
    event.preventDefault()
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"), 10)
    state.replaceItems(dragIndex, dropIndex)
    setDropping(null)
    setDragging(null)
  }

  function scrollToIndex(index: number) {
    setFocusedIndex(null)
    setTimeout(() => {
      const elements =
      Array.from(document.querySelectorAll(".EditPage-Image, .EditPage-Quote"))
      const element = elements[index]
      if (element) {
        element.scrollIntoView({ behavior: "smooth",
          block: "end", inline: "nearest" })
      }
    }, 100)
  }


  function handleUpClick(index: number) {
    if ( index === 0 ) {
      return
    }
    state.replaceItems(index, index - 1)
    scrollToIndex(index - 1)
  }

  function handleDownClick(index: number) {
    if ( index === state.items.length - 1 ) {
      return
    }
    state.replaceItems(index, index + 1)
    scrollToIndex(index + 1)
  }


  function isDndTarget(index: number) {
    return dragging === index || dropping === index
  }


  const renderItem = (item: PageItem, index: number) => {
    return <EditPageItem
      data={item.data}
      type={item.type}
      index={index}
      isWarnEmpty={isWarnEmptyFields}
      key={item.key}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnd={handleDragEnd}
      onImageChange={handleImageChange}
      onQuoteChange={handleQuoteChange}
      onDeleteItem={handleDeleteItem}
      onUpClick={handleUpClick}
      onDownClick={handleDownClick}
      focusedIndex={focusedIndex}
      isDndTarget={isDndTarget}
    />
  }

  function handleGuideClose() {
    localStorage.setItem("isTourOpen", "true");
    setIsTourOpen(false);
  }

  const guideTextStyle = css`
    color: ${colors.grayDark2};
    line-height: 1.5em;
  `
  return <div className="TimelineTemplate">
    <style>
      {`
        .TimelineTemplate {
          width: 100%;
          box-sizing: border-box;
        }
      `}
    </style>
    <div>
      <Tour
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        steps={[
          {
            selector: ".AddressBar",
            content: (
              <b className={guideTextStyle}>
              Your website is live! <br />
              You can change the URL as you want.</b>
            ),
            style: {
              "padding": "40px",
            },
          },
          {
            selector: ".ItemActions",
            content: (
              <b className={guideTextStyle}>
              Move, edit or delete photos and texts.
              </b>
            ),
            style: {
              "padding": "40px",
            },
          },
          {
            selector: "#add-item-circle-button",
            content: (
              <b className={guideTextStyle}>
              Add more photos and texts as you want.
              </b>
            ),
            style: {
              "padding": "40px",
            },
          },
        ]} isOpen={isTourOpen} onRequestClose={handleGuideClose} />
    </div>
    {renderItems()}
  </div>
})
