import React from "react";
import colors from "../colors";
import Input from "../common/Input";
import TextArea from "../common/TextArea";
import DefaultButton from "../common/DefaultButton";
import { sendContactForm } from "../../api/SupportApi";
import ErrorMessage from "../ErrorMessage";
import { validateEmail } from "../auth/Validator.util";
import { SocialLinks } from "./SocialLinks";
import { Footer } from "../homepage/Footer";
import Header from "../header/Header";

export const ContactUs = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showEmptyError, setShowEmptyError] = React.useState(false)
  const [serverErrorMessage, setServerErrorMessage] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [isSendBtnSpin, setIsSendBtnSpin] = React.useState(false);
  const [isFormSent, setIsFormSent] = React.useState(false);

  function handleMessageChange(val: string) {
    setServerErrorMessage("")
    setIsFormSent(false);
    setMessage(val);
  }
  function handleEmailChange(val: string) {
    setEmailError("");
    setIsFormSent(false);
    setServerErrorMessage("")
    setEmail(val);
  }
  function handleNameChange(val: string) {
    setServerErrorMessage("")
    setIsFormSent(false);
    setName(val);
  }
  function resetContactForm() {
    Array.from(document.querySelectorAll(".Contact-Form input,textarea"))
        .forEach((i: HTMLInputElement | HTMLTextAreaElement) => i.value= "")
  }
  async function handleSubmitClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const emailHasError = validateEmail(email)
    const anyEmptyField = !name || !email || !message
    if ( emailHasError ) {
      setEmailError(emailHasError)
    }
    if ( anyEmptyField ) {
      setShowEmptyError(true);
    }
    if ( emailHasError || anyEmptyField ) {
      return;
    }
    try {
      setIsSendBtnSpin(true);
      await sendContactForm({ name, email, message })
      resetContactForm()
      setIsFormSent(true);
    } catch (error) {
      setServerErrorMessage(error?.response?.data?.message ||
        "Something went wrong, we are working on it");
      console.error(error);
      return;
    } finally {
      setIsSendBtnSpin(false);
      setShowEmptyError(false)
    }
  }

  return (
    <>
      <Header />
      <div className="ContactUs">
        <div className="ContactUs-Channels">
          <label>
            <span className="Addressbook-Label-Text">Phone:</span>
            <span className="Addressbook-Value">
          (620) 407-9671</span>
          </label>
          <label>
            <span className="Addressbook-Label-Text">Email:</span>
            <span className="Addressbook-Value">
            support@lovepage.io</span>
          </label>
          <label>
            <span className="Addressbook-Label-Text">Address:</span>
            <span className="Addressbook-Value">
            30 N Gould St, Sheridan, WY 82801, United States</span>
          </label>
          <label>
            <span className="Addressbook-Label-Text">Social:</span>
            <span className="Addressbook-Value">
              <SocialLinks />
            </span>
          </label>
        </div>
        <h2 className="Contact-Form-Header">Contact Form</h2>
        <form className="Contact-Form">
          <label>
            <span className="Form-Label-Text">Name:</span>
            <div className="Contact-Input">
              <Input textAlign="left"
                isError={showEmptyError && !name}
                defaultValue={name} onChange={handleNameChange} />
            </div>
          </label>
          <label>
            <span className="Form-Label-Text">Email:</span>
            <div className="Contact-Input">
              <Input className="Contact-Email" textAlign="left"
                isError={showEmptyError && !email}
                defaultValue={email} onChange={handleEmailChange} />
              {emailError && <div className="Contact-Email-Error">
                <ErrorMessage message={emailError} />
              </div> }
            </div>
          </label>
          <label>
            <span className="Form-Label-Text">Message:</span>
            <div className="Contact-Input">
              <TextArea
                defaultText={message}
                onBlur={() => null}
                onChange={handleMessageChange}
                placeholder=""
                minHeight="8vh"
                isError={showEmptyError && !message}
              />
            </div>
          </label>
          <div className="Contact-Us-Submit-Button">
            {showEmptyError && (!name || !email || !message) &&
          <div className="Contact-us-error">
            <ErrorMessage message="❌ Please fill all the fields" />
          </div>
            }
            { serverErrorMessage &&
            <ErrorMessage message={serverErrorMessage} />
            }
            {!serverErrorMessage && !emailError &&
           isFormSent && <div className="Contact-us-success">
              <span>✅ You contact request has been received.
              We will get back to you shortly.</span>
            </div>}
            <DefaultButton isSpin={isSendBtnSpin}
              text="Submit" onClick={handleSubmitClick} />
          </div>
        </form>
        <style>
          {`
                .ContactUs {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    color: ${colors.white2};
                    padding-top: 2em;
                    padding-bottom: 4em;
                }
                .ContactUs-Channels {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 1em;
                    box-sizing: border-box;
                    padding: 4em 1em 4em 1em;
                }
                .ContactUs-Channels label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2em;
                    width: 100%;
                    height: 100%;
                }
                .Addressbook-Label-Text {
                    min-width: 90px;
                    font-size: 1.2em;
                    display: flex;
                    justify-content: start;
                    font-weight: 600;
                }
                .Addressbook-Value {
                    width: 100%;
                    max-width: 500px;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .Contact-Form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    gap: 1em;
                    padding: 1em;
                    box-sizing: border-box;
                }
                .Contact-Form label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2em;
                    width: 100%;
                }
                .Contact-Input {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    max-width: 500px;
                    color: ${colors.white2};
                }
                .Contact-Input textarea {
                    font-size: 1.2em;
                }
                .Form-Label-Text {
                    width: 90px;
                    font-size: 1.2em;
                    font-weight: 600;
                }
                .Contact-Us-Submit-Button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-left: 90px;
                }
                .Contact-us-error {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0 1em 1em 1em;
                }
                .Contact-Form-Header {
                    margin-left: 90px;
                    margin-bottom: 5px;
                }
                .Contact-Email-Error {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    width: 100%;
                }
                .Contact-us-success {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0 1em 1em 1em;
                }
                @media (max-width: 750px) {
                    .Contact-Form label {
                        flex-direction: column;
                        gap: 1em;
                    }
                    .Form-Label-Text {
                        width: 100%;
                        justify-content: start;
                    }
                    .Contact-Us-Submit-Button {
                        margin-left: 0px;
                    }
                    .Contact-Form-Header {
                        margin-left: 0px;
                    }
                    .ContactUs-Channels {
                      padding: 1em;
                    }
                }
            `}
        </style>
      </div>
      <Footer />
    </>
  );
};
