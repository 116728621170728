import React from "react";
import colors from "../colors";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { MdArrowBackIosNew } from "react-icons/md";

type Props = {
    children: React.ReactNode
}
const EditPageHeader: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate()

  function handleBackClick() {
    navigate(-1)
  }

  return <div className="EditPageHeader">
    <button onClick={handleBackClick} className="EditPageHeader-Logo">
      <MdArrowBackIosNew color={colors.gray} size={40} />
      <Logo />
    </button>
    <div className="EditPageHeader-Save-Btn">
      {children}
    </div>
    <style>
      {`
        .EditPageHeader-Logo { 
            display: flex;
            align-items: center;
            background-color: transparent;
            gap: -0.5em;
            border: none;
            cursor: pointer;
        }
        .EditPageHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          border-bottom: none;
          box-shadow: 0px 0px 3px 0px rgba(255, 255, 255, 0.75);
          padding: 3px 1em 3px 0px;
          font-weight: bold;
        }
      `}
    </style>
  </div>;
};

export default EditPageHeader;
