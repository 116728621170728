import React from "react";
import colors from "../../colors";
import { css } from "@emotion/css";

type Props = {
    text: string
    header: string
    photo: string
}

export const CollageItemView: React.FC<Props> = ({
  text,
  header,
  photo,
}) => {
  const paragraphStyle = css`
      color: ${colors.grayDark2};
      padding: 5px;
      margin: 0;
      border: none;
      &:focus {
          border: 1px dashed transparent;
      }
      font-weight: bold;
      font-family: 'Lora Variable', sans-serif;
      font-size: 16px;
      line-height: 24px;
      word-break: break-word;
      position: relative;
      min-width: 100%;
      white-space: pre-wrap;
      width: 100%;
      box-sizing: border-box;
  `;

  return <div className="CollageItemEditView">
    <div className="CollageItemEditView-Photo-Container">
      <img className="CollageItemEditView-Photo-Container-Photo"
        src={photo}
        alt="photo for anniversary website" />
    </div>
    <h3
      className="CollageItemEditView-Calendar">
      {header}
    </h3>
    <p className={paragraphStyle}>
      {text}
    </p>
    <style>
      {`
        .CollageItemEditView-Photo-Container-Photo {
          width: 90vw;
          max-width: 460px;
          height: 40vh;
          object-fit: contain;
          background-color: ${colors.white2};
          transform-style: preserve-3d;
          -webkit-transform-style: preserve-3d;
          box-shadow: 0 0 2px 0 rgba(0,0,0,0.7);
        }
        .CollageItemEditView-Photo-Container {
          max-height: 70vh;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: relative;
        }
        .CollageItemEditView {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          gap: 10px;
          align-items: center;
          width: 98%;
          height: 100%;
          border: 15px solid ${colors.white2};
          box-sizing: border-box;
          background-color: ${colors.white2};
          border-radius: 2px;
          box-shadow: 0 0 5px 0 rgba(0,0,0,0.7);
        }
        .CollageItemEditView-Calendar {
          margin: 0;
          color: ${colors.purple};
        }
        `}
    </style>
  </div>
}
