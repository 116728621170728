import React, { useState } from "react";
import ProfileBox from "../dashboard/ProfileBox";
import colors from "../colors";
import Logo from "./Logo";
import DefaultButton from "../common/DefaultButton";
import { UpgradeModal } from "../upgrade/UpgradeModal";
import { MdOutlineEmail, MdWorkspacePremium } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { EnterEmailButton } from "../common/EnterEmailButton";

type Props = {
  showUpgradeButton?: boolean;
  showRegisterDeviceButton?: boolean;
}

const AuthHeader: React.FC<Props> = ({ showUpgradeButton = false,
  showRegisterDeviceButton }) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const navigate = useNavigate()

  function handleUpgradeClick() {
    setShowUpgradeModal(true)
  }

  const upgradeButtonIcon = <MdWorkspacePremium
    color={colors.white2}
    size={25} />

  return <div className="AuthHeader">
    <div className="AuthHeader-Logo">
      <Logo />
    </div>
    {showUpgradeButton && <div className="AuthHeader-Upgrade-Button">
      <DefaultButton size="medium"
        color="green"
        icon={upgradeButtonIcon}
        className="AuthHeader-Upgrade-Button-DefaultButton"
        onClick={handleUpgradeClick} text="Upgrade" />
    </div>
    }

    {showRegisterDeviceButton &&
      <div className="Enter-Email-Button">
        <EnterEmailButton />
      </div>
    }


    {showUpgradeModal &&
     <UpgradeModal isAuth={true}
       onClose={() => setShowUpgradeModal(false)} /> }
    <div className="ProfileBox">
      <ProfileBox/>
    </div>
    <style>
      {`
        .AuthHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          width: 100%;
          box-sizing: border-box;
          border-bottom: 0.5px solid ${colors.gray};
          padding: 3px 1em 3px 1em;
        }
        .ProfileBox {
          display: flex;
          justify-content: right;
        }
        .AuthHeader-Upgrade-Button {
          width: 8em;
        }
        .Enter-Email-Button {
          width: 10em;
        }
      `}
    </style>
  </div>;
};

export default AuthHeader;
