import React from "react";
import SelectImage from "./SelectImage";
import QuoteItem from "./QuoteItem";
import colors from "../colors";
import { PageItemType } from "./dto/PageItemType";

type Props = {
    data: string
    type: PageItemType;
    index: number;
    isWarnEmpty: boolean;
    onDragStart: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
    onDragOver: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
    onDrop: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
    onDragEnd: (e: React.DragEvent<HTMLDivElement>, index: number) => void;
    onImageChange: (index: number, val: string) => void;
    onQuoteChange: (index: number, val: string) => void;
    onDeleteItem: (index: number) => void;
    onUpClick: (index: number) => void;
    onDownClick: (index: number) => void;
    focusedIndex: number;
    isDndTarget: (index: number) => boolean;
}
export const EditPageItem: React.FC<Props> =
 ({ data, type, index, isWarnEmpty,
   onDragStart, onDragOver, onDrop, onDragEnd,
   onImageChange, onQuoteChange, onDeleteItem,
   onUpClick, onDownClick, focusedIndex, isDndTarget,
 }) => {
   const isInvalid = !data && isWarnEmpty
   if (type === PageItemType.PHOTO) {
     return (
       <div
         onDragStart={(_e) => onDragStart(_e, index)}
         onDragOver={(e) => onDragOver(e, index)}
         onDrop={(e) => onDrop(e, index)}
         onDragEnd={(e) => onDragEnd(e, index)}
         draggable="true" className="EditPage-Image">
         {isInvalid && <
           div className="EditPage-Empty-Error">- Please select an image</div>}
         <SelectImage
           isDndTarget={isDndTarget(index)}
           image={data}
           onChange={onImageChange}
           onDelete={onDeleteItem}
           onDownClick={onDownClick}
           onUpClick={onUpClick}
           index={index}
         />
         <style>
           {`
                .EditPage-Image {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    box-sizing: border-box;
                    padding: 2em;
                  }
                .EditPage-Empty-Error {
                color: ${colors.red};
                padding: 0.5em 1em;
                }
            `}
         </style>
       </div>
     )
   } else {
     return (
       <div
         draggable="true" onDragStart={(_e) => onDragStart(_e, index)}
         onDragOver={(e) => onDragOver(e, index)}
         onDrop={(e) => onDrop(e, index)}
         onDragEnd={(e) => onDragEnd(e, index)}
         className="EditPage-Quote">
         {isInvalid && <
           div className="EditPage-Empty-Error">- Please write text</div>}
         <QuoteItem
           onUpClick={onUpClick}
           onDownClick={onDownClick}
           isDndTarget={isDndTarget(index)}
           autoFocus={focusedIndex === index}
           text={data}
           onChange={onQuoteChange}
           onDelete={onDeleteItem}
           index={index}
         />
         <style>
           {`
                .EditPage-Quote {
                    height: 100%;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 2em 2em 0 2em;
                  }
            `}
         </style>
       </div>
     )
   }
 }
