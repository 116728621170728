import React from "react";
import DefaultButton from "../common/DefaultButton";

type Props = {
    onClose: () => void
}
export const RefundDialogFooter: React.FC<Props> =
 ({ onClose }) => {
   return <div className="Refund-Dialog-Footer">
     <div className="Refund-Dialog-Footer-Item">
       <DefaultButton
         onClick={onClose}
         size="medium"
         className="Refund-Dialog-OK-Button"
         text="OK" />
     </div>
     <style>
       {`
            .Refund-Dialog-Footer {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: row;
                width: 100%;
                gap: 1em;
                height: 80px;
                font-weight: bold;
            }
            .Refund-Dialog-Footer-Item {
                width: 10em;
            }
        `}
     </style>
   </div>
 }
