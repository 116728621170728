import React from "react";
import Dialog from "../dialog/Dialog";
import { RefundDialogBody } from "./RefundDialogBody";
import { RefundDialogFooter } from "./RefundDialogFooter";

type Props = {
    onClose: () => void
}
export const RefundDialog: React.FC<Props> = ({
  onClose,
}) => {
  return <Dialog footer={<RefundDialogFooter
    onClose={onClose}
  />}
  onClose={onClose}>
    <RefundDialogBody />
  </Dialog>
}
