type PaymentStatus = "SUCCEEDED" | "CANCELED" | "FAILED";

export function usePaymentResult(): PaymentStatus {
  const params = new URLSearchParams(
      window.location.search,
  );
  const success = params.get("success");
  const canceled = params.get("canceled");
  return success === "true" ? "SUCCEEDED" :
  canceled === "true" ? "CANCELED" : "FAILED";
}
