import React, { useState } from "react";
import DefaultButton from "../common/DefaultButton";

type Props = {
    isDeleteButtonSpin: boolean
    onDeleteConfirm: () => void
    onDeleteCancel: () => void
}
export const DeletePageDialogFooter: React.FC<Props> =
 ({ isDeleteButtonSpin, onDeleteConfirm, onDeleteCancel }) => {
   return <div className="Delete-Dialog-Footer">
     <div className="Delete-Dialog-Footer-Item">
       <DefaultButton
         isSpin={isDeleteButtonSpin}
         onClick={onDeleteConfirm}
         size="medium"
         text="Delete" />
     </div>
     <div className="Delete-Dialog-Footer-Item">
       <DefaultButton
         disabled={isDeleteButtonSpin}
         onClick={onDeleteCancel}
         size="medium"
         text="Cancel" />
     </div>
     <style>
       {`
            .Delete-Dialog-Footer {
                display: flex;
                justify-content: space-around;
                align-items: center;
                flex-direction: row;
                width: 100%;
                gap: 1em;
                height: 80px;
                font-weight: bold;
            }
            .Delete-Dialog-Footer-Item {
                width: 10em;
            }
        `}
     </style>
   </div>
 }
