import React, { useContext } from "react";
import { AppContext } from "../AppContext";
import { isProduction } from "../../config";
import { LoadingState } from "../../common/LoadingState";

export function usePageUrl(pageId: number) {
  const { state } = useContext(AppContext);
  const pages = state.pages.state === LoadingState.LOADED ?
  state.pages.data : null
  if (!pages) return ""

  const subdomain = pages.find((p) => p.id === pageId)?.subdomain || ""
  const domain = isProduction ? ".lovepage.io" : ".localhost:3000"
  const protocol = isProduction ? "https" : "http"
  const url = `${protocol + "://" + subdomain + domain}`
  return url
}
