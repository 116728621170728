import React from "react";
import RemoveButtonCircle from "../common/RemoveButtonCircle";
import EditButtonCircle from "../common/EditButtonCircle";
import UpButtonCircle from "../common/UpButtonCircle";
import DownButtonCircle from "../common/DownButtonCircle";

type Props ={
    onDeleteClick: () => void;
    onEditClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onUpClick: () => void;
    onDownClick: () => void;
    size?: "small" | "large";
    isDisabled?: boolean;
    isEditHidden?: boolean;
}

export const ItemActions: React.FC<Props> =
 ( { onDeleteClick, onEditClick, onUpClick, onDownClick, size,
   isDisabled = false, isEditHidden = false }) => {
   return (
     <div className="ItemActions">
       {/* <UpButtonCircle isDisabled={isDisabled}
         size={size} onClick={onUpClick} />
       <DownButtonCircle isDisabled={isDisabled}
         size={size} onClick={onDownClick} /> */}
       {isEditHidden === false &&
        <EditButtonCircle isDisabled={isDisabled}
          size={size} onClick={onEditClick} />
       }
       <RemoveButtonCircle isDisabled={isDisabled}
         size={size} onClick={onDeleteClick} />
       <style>
         {`
            .ItemActions {
                display: flex;
                gap: 0.5em;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
         `}
       </style>
     </div>
   );
 };
