import { useState } from "react"
import { EditPageState } from "../../state/EditPageState"

type EditBehavior = {
    setFocusedIndex: (index: number | null) => void
    setDragging: (index: number | null) => void
    setDropping: (index: number | null) => void
    dragging: number | null
    dropping: number | null
    focusedIndex: number | null
    handleDragStart: (event: React.DragEvent<HTMLDivElement>,
        index: number) => void
    handleDragOver: (event: React.DragEvent<HTMLDivElement>,
        index: number) => void
    handleDragEnd: (event: React.DragEvent<HTMLDivElement>,
        index: number) => void
    handleDrop: (event: React.DragEvent<HTMLDivElement>,
        dropIndex: number) => void
    handleUpClick: (index: number) => void
    handleDownClick: (index: number) => void
    scrollToIndex: (index: number) => void
    isDndTarget: (index: number) => boolean
    handleQuoteChange: (index: number, quote: string) => void
    handleImageChange: (index: number, photo: string) => void
    handleDeleteItem: (index: number) => void
}


export default function useEditBehavior(state: EditPageState): EditBehavior {
  const [dragging, setDragging] = useState<number | null>(null)
  const [dropping, setDropping] = useState<number | null>(null)
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null)

  function handleDragStart(event: React.DragEvent<HTMLDivElement>,
      index: number) {
    setDragging(index)
    event.dataTransfer.setData("text/plain", index.toString())
    event.dataTransfer.effectAllowed = "move"
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>,
      index: number) {
    event.preventDefault()
    setDropping(index)
    event.dataTransfer.dropEffect = "move"
  }

  function handleDragEnd(event: React.DragEvent<HTMLDivElement>,
      index: number) {
    setDragging(null)
    setDropping(null)
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>,
      dropIndex: number) {
    event.preventDefault()
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"), 10)
    state.replaceItems(dragIndex, dropIndex)
    setDropping(null)
    setDragging(null)
  }

  function handleUpClick(index: number) {
    if ( index === 14 ) {
      return
    }
    state.replaceItems(index, index - 1)
    scrollToIndex(index - 1)
  }

  function handleDownClick(index: number) {
    if ( index === state.items.length - 1 ) {
      return
    }
    state.replaceItems(index, index + 1)
    scrollToIndex(index + 1)
  }


  function scrollToIndex(index: number) {
    setTimeout(() => {
      const elements =
          Array.from(
              document.querySelectorAll(".EditPage-Image, .EditPage-Quote"),
          )
      const element = elements[index - 14]

      if (element) {
        element.scrollIntoView({ behavior: "smooth",
          block: "end", inline: "nearest" })
      }
    }, 100)
  }

  function isDndTarget(index: number) {
    return dragging === index || dropping === index
  }

  function handleQuoteChange(index: number, quote: string) {
    state.updateItem(index, quote)
    setFocusedIndex(index)
  }

  function handleImageChange(index: number, photo: string) {
    state.updateItem(index, photo)
  }

  function handleDeleteItem(index: number) {
    state.deleteItem(index)
  }
  return {
    dragging,
    dropping,
    focusedIndex,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDrop,
    handleUpClick,
    handleDownClick,
    scrollToIndex,
    isDndTarget,
    handleQuoteChange,
    handleImageChange,
    handleDeleteItem,
    setDragging,
    setDropping,
    setFocusedIndex,
  }
}
