import React, { useEffect, useRef } from "react";
import colors from "../../colors";
import { cx, css } from "@emotion/css";
import "@fontsource-variable/montserrat";
import { observer } from "mobx-react-lite";

const MAX_LENGTH = 165

type Props = {
    text: string;
    onChange: (text: string) => void;
    className: string;
    textKey: string
}

export const EditableH3:
React.FC<Props> = observer(({ text, onChange, className, textKey }) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const [initialText, setInitialText] = React.useState<string>(text);
  useEffect(() => {
    setInitialText(text);
  }, [textKey])

  function handleInput(e: React.FormEvent<HTMLHeadingElement>) {
    if (e.currentTarget.innerText.length > MAX_LENGTH) {
      e.currentTarget.innerText =
      e.currentTarget.innerText.slice(0, MAX_LENGTH)
      document.execCommand("selectAll", false, null);
      // collapse selection to the end
      document.getSelection().collapseToEnd();
    }
    onChange(e.currentTarget.innerText);
  }

  useEffect(() => {
    (document.querySelector(`.EditableH3-${className}`) as HTMLHeadingElement)
        .innerText = text
  }, [])

  function handleFocus() {
    window.getSelection().selectAllChildren(ref.current)
    window.getSelection().collapseToEnd()
  }

  const styles = css`
    color: ${colors.purple};
    padding: 5px;
    margin: 0;
    border: 1px dashed black;
    &:focus {
        border: none
    }
    white-space: pre-wrap;
    `;

  return <h3 onFocus={handleFocus}
    ref={ref}
    onInput={handleInput}
    suppressContentEditableWarning={true}
    contentEditable={true}
    className={cx(`EditableH3-${className}`, styles)}>
    {initialText}
  </h3>;
},
)
