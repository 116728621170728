import React from "react";
import AddItemButton from "./AddItemButton";
import colors from "../colors";
import { IoText } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";

type Props = {
  onPhotoClick: () => void;
  onTextClick: () => void;
}

const AddActionButtons: React.FC<Props> = ({ onPhotoClick, onTextClick }) => {
  const addTextBtnIcon = <IoText data-testid="add-text-button"
    className="AddTextButton-Icon" size="50"
    color={colors.white2}/>
  const addImageBtnIcon = <RiImageAddFill data-testid="add-photo-button"
    className="AddPhotoButton-Icon" size="50"
    color={colors.white2}/>
  return <>
    <div className="AddActionButtons-Container">
      <AddItemButton onClick={onTextClick}
        icon={addTextBtnIcon}
        text="Text" />
      <AddItemButton onClick={onPhotoClick}
        icon={addImageBtnIcon}
        text="Image" />
    </div>
    <style>
      {`
        .AddActionButtons-Container {
            display: flex;
            align-items: center;
            padding: 0.5em;
            gap: 0.5em;
            background-color: ${colors.grayDark2};
            border-radius: 5px;
        }
      `}
    </style>
  </>;
};

export default AddActionButtons;
