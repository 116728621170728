import React from "react";
import { MdIosShare } from "react-icons/md";
import DefaultButton from "../common/DefaultButton";

export const SharePageButton: React.FC = () => {
  const address = window.location.href

  async function handleShareClick() {
    try {
      await navigator.share({
        title: "See my Love Page",
        url: address,
      });
    } catch (error) {
      console.log("Share failed: " + error);
      await navigator?.clipboard?.writeText?.(address);
    }
  }

  return <div className="SharePageButton">
    <DefaultButton onClick={handleShareClick}
      icon={<MdIosShare className="Share-Icon" size={35}/>}
      text={"Share"} />
    <style>
      {`
            .SharePageButton {
                max-width: 2200px;
            }
        `}
    </style>


  </div>;
};
