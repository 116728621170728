import React, { useContext, useState } from "react";
import { AppContext, AppContextAction } from "../AppContext";
import { logout } from "../../api/UserApi";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { LoadingState } from "../../common/LoadingState";
import { useLovePageUrl } from "./useLovePageUrl";
import colors from "../colors";
import { MdLogout, MdOutlineDashboard, MdOutlineManageAccounts,
  MdOutlineSupport,
  MdWorkspacePremium } from "react-icons/md";
import { FaCaretDown } from "react-icons/fa";

const ProfileBox: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate()
  const lovepageUrl = useLovePageUrl()
  const user = state.userDto.state === LoadingState.LOADED ?
    state.userDto.data : null;

  async function handleLogout(): Promise<void> {
    localStorage.removeItem("deviceId");
    await logout();
    dispatch({
      type: AppContextAction.LOGOUT,
    });
    navigate("/")
  }

  function handleDashboardClick(): void {
    window.location.href = lovepageUrl + "/dashboard"
  }

  function handleAccountClick(): void {
    window.location.href = lovepageUrl + "/account"
  }

  function handleSupportClick(): void {
    window.location.href = lovepageUrl + "/support"
  }

  function handleUpgradeClick(): void {
    window.location.href = lovepageUrl + "/upgrade"
  }

  if (!user) return null;

  const caretIcon = (<FaCaretDown className="ProfileBox-Caret"
    size="20"/>);

  return <div className="ProfileBox">
    <button onClick={() => setShowDropdown(
        (v) => !v,
    )}
    onBlur={() => setTimeout(() => {
      setShowDropdown(false)
    }, 200)}
    className="ProfileBox-Button">
      {user.picture &&
      <img src={user.picture}
        className="ProfileBox-Icon-Image"
        alt="profile picture" referrerPolicy="no-referrer"/>
      }
      {!user.picture && <CgProfile className="ProfileBox-Icon"
        size="45px" />}
      {caretIcon}
    </button>
    <div className="ProfileBox-Content">
      <a className="ProfileBox-Item"
        onClick={handleDashboardClick}>
        <MdOutlineDashboard
          color={colors.grayDark2}
          size={30} />
        <span>Dashboard</span>
      </a>
      <a className="ProfileBox-Item"
        onClick={handleUpgradeClick}>
        <MdWorkspacePremium
          color={colors.grayDark2}
          size={30} />
        <span>Upgrade</span>
      </a>
      <a className="ProfileBox-Item"
        onClick={handleAccountClick}>
        <MdOutlineManageAccounts
          color={colors.grayDark2}
          size={30} />
        <span>Account</span>
      </a>
      <a className="ProfileBox-Item"
        onClick={handleSupportClick}>
        <MdOutlineSupport
          size={30} color={colors.grayDark2} />

        <span>Support</span>
      </a>
      <a className="ProfileBox-Item"
        onClick={handleLogout}>
        <MdLogout
          size={30} color={colors.grayDark2} />
        <span>Logout</span>
      </a>
    </div>
    <style>
      {`
        .ProfileBox-Icon-Image {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
        }
        .ProfileBox {
          position: relative;
        }
        .ProfileBox-Button {
          cursor: pointer;
          background-color: transparent;
          border: none;
          display: flex;
          align-items: center;
          color: ${colors.white2};
        }
        .ProfileBox-Button:hover {
          background-color: rgb(220,220,220, 0.2);
          border-radius: 3px;
        }

        .ProfileBox-Content {
            position: absolute;
            display: flex;
            flex-direction: column;
            opacity: ${showDropdown ? 1 : 0};
            border-radius: 5px;
            padding: 2px;
            background-color: black;
            margin-top: 1px;
            box-shadow: 0 0 1px ${colors.grayDark};
            width: 7em;
            z-index: ${showDropdown ? 1 : -2};
            background-color: ${colors.white2};
            top: 46px;
            right: 0;
            border: 2px solid ${colors.grayDark2};
        }

        .ProfileBox-Item {
            width: 100%;
            cursor: pointer;
            padding: 5px 3px;
            border: none;
            border-bottom: 1px solid ${colors.grayDark};
            display: flex;
            align-items: center;
            box-sizing: border-box;
            gap: 5px;
        }
        .ProfileBox-Item:last-child {
            border-bottom: none;
        }

        .ProfileBox-Item:hover {
            background-color: rgb(220,220,220,1);
        }

        .ProfileBox-Item > span {
            word-break: initial;
            color: black;
            font-weight: 500;
            width: 100%;
            text-align: left;
        }
        .Dropdown-Icon {
            color: black;
        }
      `}
    </style>
  </div>;
};

export default ProfileBox;
