import React from "react";
import Dialog from "../dialog/Dialog";
import { DeleteAccountDialogFooter } from "./DeleteAccountDialogFooter";
import { DeleteAccountDialogBody } from "./DeleteAccountDialogBody";

type Props = {
    isDeleteButtonSpin: boolean
    onDeleteConfirm: () => void
    onDeleteCancel: () => void
}
export const DeleteAccountDialog: React.FC<Props> = ({
  isDeleteButtonSpin,
  onDeleteConfirm,
  onDeleteCancel,
}) => {
  return <Dialog footer={<DeleteAccountDialogFooter
    isDeleteButtonSpin={isDeleteButtonSpin}
    onDeleteConfirm={onDeleteConfirm}
    onDeleteCancel={onDeleteCancel} />}
  onClose={() => isDeleteButtonSpin ? null : onDeleteCancel()}>
    <DeleteAccountDialogBody />
  </Dialog>
}
