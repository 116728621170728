import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../common/DefaultButton";
import { AppContext } from "../AppContext";
import { LoadingState } from "../../common/LoadingState";
import colors from "../colors";
import { AiFillStar } from "react-icons/ai";
import { Footer } from "./Footer";
import Header from "../header/Header";
import { HomepageTemplates } from "./HomepageTemplates";
import { css } from "@emotion/css";
import "@fontsource/barlow-semi-condensed";
import { FeatureColumns } from "./FeatureColumns";
import { HappyCustomers } from "./HappyCustomers";
import { SecondFeatureColumns } from "./SecondFeatureColumns";
import { useTranslation } from "react-i18next";

const HomePage: React.FC = () => {
  const { state } = useContext(AppContext)
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  console.log(currentLanguage);


  const { pathname } = window.location
  if ( pathname !== "/" ) {
    navigate("/")
  }

  useEffect(() => {
    if (state.userDto.state === LoadingState.LOADED) {
      navigate("/dashboard")
      return
    }

    // Select the element to be animated
    const element = document.querySelector(".Select-Template-Text");

    // Create a new Intersection Observer
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If the element is visible, add the class to trigger the animation
          element.classList.add("Animate-Typing");
        } else {
          // If the element is not visible, remove the class
          // element.classList.remove("Animate-Typing");
        }
      });
    });

    // Start observing the element
    observer.observe(element);
  }, [state.userDto])

  function handleStartClick(): void {
    navigate("/templates")
  }

  const headlineStyle = css`
    text-align: left;
    width: 100%;
    max-width: 370px;
    padding: 0 1em;
    box-sizing: border-box;
    h2 {
      font-weight: bold;
      color: ${colors.white2};
      margin: 1em 0 0.5em 0;
      font-family: 'Barlow Semi Condensed', sans-serif;
      font-size: 1.8em;
    }
    span {
      color: ${colors.quotesText};
    }
    h3 {
      font-weight: bold;
      margin: 0 0 1em 0;
      font-size: 1em;
      line-height: 1.5em;
    }
    h3 > span {
      color: ${colors.white2};
      font-style: italic;
      font-weight: normal;
    }
  `

  const firstPhotoContainerStyle = css`
  width: fit-content;
  height: fit-content;
  position: relative;
  `

  const firstPhotoStyle = css`
    width: 100%;
    height: 40vh;
    max-width: 350px;
    max-height: 440px;
    object-fit: contain;
    @media (min-width: 550px) {
      max-width: 80vw;
      max-height: 100%;
    }
    border-radius: 5px;
    `

  const secondFeaturesHeadlineStyle = css`
    width: 100%;
    font-size: 1.5em;
    padding: 3em 1em 0em 1em;
    box-sizing: border-box;
    h3 {
      margin: 0;
      line-height: 1.4em;
    }
    p {
      font-size: 0.7em;
      line-height: 1.5em;
    }
  `

  const tryOutTextStyle = css`
    margin: 1em 0 0 0;
    padding: 1em 1em 0.5em 1em;
    font-size: 1.2em;
    line-height: 1.5em;
    h3 {
      margin: 0;
      display: inline;
    }
    text-align: left;
    width: 90%;
  `

  return (
    <div className="HomePage">
      <Header />
      <div className={headlineStyle}>
        { currentLanguage === "en" &&
         <h2>Create an <span>I love you</span> website</h2> }
        { currentLanguage === "tr" &&
         <h2><span>Seni seviyorum </span> sürprizi hazırla</h2> }
        <h3>{t("young-and-romantic")}
          <span> { t("no-coding-skills-required") } </span></h3>
      </div>
      <div className={firstPhotoContainerStyle}>
        <img className={firstPhotoStyle}
          pre-load="true"
          src="https://ik.imagekit.io/ex5o5cwj51/main-image-small.jpeg?updatedAt=1698003807847" alt="" />
      </div>
      <div className="HomePage-Start-Btn">
        <DefaultButton className="Get-Started-Btn"
          color="green"
          text={t("get-started")} onClick={handleStartClick} />
      </div>
      <div className="Info-Row">
        <div className="Info-Row-First-Column">
        +3,000 Websites <br /> Created
        </div>
        <div className="Info-Row-Second-Column">
          <div className="Review-Container">
            <div className="Review-First-Row">
              Customer Satisfaction Rate
            </div>
            <div className="Review-Second-Row">
              <div className="Review-Text">
                5.0
              </div>
              <div className="Review-Stars">
                <AiFillStar className="Review-Star-Icon" size={25} />
                <AiFillStar className="Review-Star-Icon" size={25} />
                <AiFillStar className="Review-Star-Icon" size={25} />
                <AiFillStar className="Review-Star-Icon" size={25} />
                <AiFillStar className="Review-Star-Icon" size={25} />
              </div>
            </div>
            <div className="Review-Third-Row">
              <div style={{ opacity: 0 }} className="Review-Text">
                5.0
              </div>
              <div>
                Based on 53 reviews
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="Select-Template-Text">{t("start-with-a-template")}</h2>
      <div className="Demo-View-Container">
        <HomepageTemplates />
      </div>
      <div className="HomePage-Info-Area">

        <div className={secondFeaturesHeadlineStyle}>
          <h3>{ t("why-lovepage-io") }</h3>
          <p>
            { t("why-lovepage-io-sub") }
          </p>
        </div>

        <FeatureColumns />

        <HappyCustomers />

        <div className={secondFeaturesHeadlineStyle}>
          <h3>{ t("special-way-to-express-your-love") }</h3>
        </div>

        <SecondFeatureColumns />

        <div className="Homepage-Video-Tutorial">
          <h3>{t("watch-our-video-tutorial")}<br/>
            {t("create-in-2-mins")}
          </h3>
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/6tmIi0mF3Ys"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay;
             clipboard-write; encrypted-media;
              gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
        </div>
        <p className={tryOutTextStyle}>
          {t("there-is-no-better-way")}
        &nbsp;<h3>{t("try-it-now")}</h3>
        </p>
        <div className="HomePage-Start-Btn">
          <DefaultButton className="Get-Started-Btn-2"
            color="green"
            text={t("get-started")} onClick={handleStartClick} />
          <span>No Credit Card required</span>
        </div>
      </div>

      <Footer />
      <style>
        {`
        @keyframes typing {
          from {
            width: 0;
          }
          to {
            width: 100%;
          }
        }
        .Homepage-Video-Tutorial {
          width: 100%;
          max-width: 600px;
          margin-top: 1em;
          margin-bottom: 2em;
          padding: 5px;
          box-sizing: border-box;
        }
        .Homepage-Video-Tutorial > * {
          width: 100%;
        }
        .Homepage-Video-Tutorial > iframe {
          margin-top: 1em;
        }
        .Homepage-Video-Tutorial > h3 {
          line-height: 1.5em;
          padding: 0 0.5em;
          box-sizing: border-box;
        }
          .Animate-Typing {
            text-align: left;
            box-sizing: border-box;
            line-height: 1.5em;
            animation: typing 1s steps(20, end) forwards;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            width: 0; 
          }
          .Select-Template-Text {
            margin-top: 2em;
            box-sizing: border-box;
            line-height: 1.5em;
            padding: 0 0.5em;
            text-align: center;
            margin-bottom: 0;
          }
          .Demo-View-Container {
            width: 100%;
            height: 75vh;
            overflow-x: auto;
            border-bottom: 2px solid rgb(255, 255, 255, 0.5);
            shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
          }
          .Review-Second-Row {
            display: flex;
            font-size: 13px;
          }
          .Review-Stars {
            display: flex;
          }
          .Review-Text {
            font-size: 24px;
            font-weight: bold;
            color: ${colors.white};
          }
          .Review-Container {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            justify-content: center;
          }
          .Review-Second-Row,.Review-First-Row, .Review-Third-Row {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-family: Roboto,Arial,sans-serif;
            flex: 1;
            line-height: 1.3em;
          }
          .Info-Row-First-Column, .Info-Row-Second-Column {
            flex: 1;
          }
          .Info-Row-First-Column {
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: start;
            text-align: center;
            padding-left: 10px;
            line-height: 25px;;
          }
          .Info-Row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 80px;
            font-size: 14px;
            background-color: rgba(255, 255, 255, 0.2);
            margin-top: 1em;
          }
          .HomePage {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            color: ${colors.white2}
          }
          .HomePage-Start-Btn {
            padding: 4em 0 6em 0;
            width: 160px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5em;
            font-size: 0.8em;
          }
          .Satisfaction-Rate {
            font-size: 0.8em;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .HomePage-Info-Area {
            width: 100%;
            max-width: 750px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          @media (min-width: 550px) {
            .First-Screen {
              width: 550px;
            }
            .Info-Row {
              justify-content: space-around;
            }
            .Info-Row-First-Column {
              justify-content: center;
            }
            .Footer {
              flex-direction: row;
            }
          }
        `}
      </style>
    </div>
  );
};

export default HomePage;
