import React from "react";
import colors from "../colors";

type Props = {
  text: string
}

const QuoteView: React.FC<Props> = ({ text }) => {
  return (
    <div className="QuoteView">
      <p className="QuoteView-Text">{ text }</p>
      <style>
        {`
            .QuoteView-Text {
              padding: 0;
              margin: 0;
              width: 100%;
              min-height: 2vh;
              cursor: text;
              background: transparent;
              color: ${colors.white2};
              font-size: 1.8em;
              font-weight: 700;
              font-style: italic;
              font-family: 'Lora Variable', sans-serif;
               0 0 5px ${colors.grayDark};
               white-space: pre-line;
               word-break: break-word;
            }
            .QuoteView {
              display: flex;
              align-items: center;
              padding: 1em;
              width: 100%;
              box-sizing: border-box;
              box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1);
              font-style: italic;
            }
          `}
      </style>
    </div>
  );
};

export default React.memo(QuoteView);
