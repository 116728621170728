import React, { useEffect } from "react";
import UserDto from "../api/dtos/UserDto";
import PageDto from "../api/dtos/PageDto";
import { ViewState } from "../common/ViewState";
import { LoadingState } from "../common/LoadingState";
import { getUser } from "../api/UserApi";
import { signInWithDeviceId } from "../api/AuthApi";

export interface AppContextStateDto {
  userDto?: ViewState<UserDto>,
  pages?: ViewState<PageDto[]>,
}

export enum AppContextAction {
  SIGN_IN,
  SIGN_UP,
  PAGE_LOAD,
  USER_LOAD,
  LOGOUT,
}

export interface AppContextActionDto extends AppContextStateDto {
  type: AppContextAction;
}

const initialState: AppContextStateDto = {
  userDto: { state: LoadingState.IDLE },
  pages: { state: LoadingState.IDLE },
};

function setUserIdForTracking(userDto: ViewState<UserDto>) {
  if (userDto.state !== LoadingState.LOADED || !userDto.data?.email) {
    return;
  }
  // @ts-ignore
  window.OpenReplay?.setUserID(userDto.data.email);
}

const defaultDispatch: React.Dispatch<AppContextActionDto> = () => initialState;
export const AppContext = React.createContext(
    { state: initialState, dispatch: defaultDispatch });

function contextReducer(state: AppContextStateDto,
    action: AppContextActionDto): AppContextStateDto {
  switch (action.type) {
    case AppContextAction.SIGN_IN:
    case AppContextAction.SIGN_UP:
      setUserIdForTracking(action.userDto);
      return { ...initialState, userDto: action.userDto };
    case AppContextAction.PAGE_LOAD:
      return { ...state, pages: action.pages };
    case AppContextAction.USER_LOAD:
      setUserIdForTracking(action.userDto);
      return { ...state, userDto: action.userDto };
    case AppContextAction.LOGOUT:
      setUserIdForTracking({ state: LoadingState.NO_AUTH });
      return { ...state, userDto: { state: LoadingState.NO_AUTH },
        pages: { state: LoadingState.NO_AUTH } };
    default:
      throw new Error(`Unhandled action: ${action.type}`);
  }
}

export const AppContextProvider: React.FC = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer(contextReducer, initialState);
  const value = { state, dispatch };

  useEffect(() => {
    const loadUser = async () => {
      try {
        const userDto = await getUser();
        dispatch({
          type: AppContextAction.USER_LOAD,
          userDto: { state: LoadingState.LOADED, data: userDto },
        })
      } catch (error) {
        try {
          const deviceId = localStorage.getItem("deviceId");
          if ( deviceId ) {
            await signInWithDeviceId(deviceId);
            const userDto = await getUser();
            dispatch({
              type: AppContextAction.USER_LOAD,
              userDto: { state: LoadingState.LOADED, data: userDto },
            })
          } else {
            throw new Error("No device id");
          }
        } catch (error) {
          dispatch({
            type: AppContextAction.USER_LOAD,
            userDto: { state: LoadingState.FAILED,
              errorMessage: error?.message },
          })
        }
      }
    }
    loadUser();
  }, [])
  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
