import React from "react";
import colors from "../colors";
import { AiOutlineExpandAlt } from "react-icons/ai";

type Props = {
  onClick: () => void;
  size?: "small" | "large";
}

const ExpandButton: React.FC<Props> = ({ onClick, size }) => {
  const iconSize = size === "small" ? 20 : 30;
  return <div className="ExpandButton-Container"
    onClick={onClick}>
    <AiOutlineExpandAlt
      style={{ fill: colors.white }}
      className="ExpandButton" size={iconSize} />
    <style>
      {`
        .ExpandButton-Container {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(0, 0, 0, 0.7);
          border-radius: 50%;
          padding: 4px;
          cursor: pointer;
          border: none;
        }
        .ExpandButton-Container:hover {
          -webkit-filter: brightness(70%);;
        }
      `}
    </style>
  </div>;
};

export default ExpandButton;
