import React, { useEffect, useState } from "react";
import { CollageItemEditView } from "./CollageItemEditView";
import { IoCloseSharp } from "react-icons/io5";
import colors from "../../../colors";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { EditPageState } from "../../state/EditPageState";
import { observer } from "mobx-react-lite";

type Props = {
    onClose: () => void;
    selectedItemIndex: number
    state: EditPageState;
}

export const CollageEditModal: React.FC<Props> = observer(({
  onClose,
  state,
  selectedItemIndex,
}) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] =
   useState<number>(selectedItemIndex);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  function handleNavigateRightClick() {
    setSelectedPhotoIndex(
        (selectedPhotoIndex + 1) % 4,
    )
  }

  function handleNavigateLeftClick() {
    setSelectedPhotoIndex(
        (selectedPhotoIndex === 0 ? 3 : selectedPhotoIndex - 1),
    )
  }

  return <div className="CollageEditModal">
    <div className="CollageEditModal-Container">
      <div className="CollageEditModal-Close-Btn">
        <IoCloseSharp color={colors.white2} onClick={onClose}
          className="Close-Icon" size={40} />
      </div>
      <div className="CollageEditModal-Content">
        <CollageItemEditView
          photoIndex={selectedPhotoIndex}
          state={state}
        />
      </div>
      <div className="CollageEditModal-Navigate-Btns">
        <FaChevronLeft color={colors.white2} onClick={handleNavigateLeftClick}
          className="Navigate-Left-Icon" size={40} />
        <FaChevronRight color={colors.white2} onClick={handleNavigateRightClick}
          className="Navigate-Right-Icon" size={40} />
      </div>
    </div>
    <style>
      {`  
            .CollageEditModal-Container {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: center;
              width: 97vw;
              height: 80vh;
              max-width: 500px;
              max-height: 450px;
            }
            .CollageEditModal-Navigate-Btns {
              display: flex;
              gap: 1em;
              padding: 1em;
            }
            .Navigate-Right-Icon, .Navigate-Left-Icon {
              cursor: pointer;
            }
            .CollageEditModal {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                height: 100%;
                z-index: 5;
                bottom: 0;
                padding-top: 1em;
                position: fixed;
                background-color: rgba(0, 0, 0, 0.5);
              }
              .CollageEditModal-Close-Btn {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                height: 50px;
                padding: 0 10px;
                flex-direction: row;
                cursor: pointer;
                z-index: 2;
                top: 0;
                height: fit-content;
                box-sizing: border-box;
              }
              .CollageEditModal-Content {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-height: 83vh;
                height: fit-content;
                box-sizing: border-box;
                border-radius: 5px;
              }
              .Close-Icon > path {
                stroke: white;
              }
              .Close-Icon > fill {
                color: white;
              }
            `}
    </style>
  </div>;
})
