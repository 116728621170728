import { useState } from "react"
import { EditPageState } from "../../state/EditPageState"

export type AnniversaryTemplateBehavior = {
    handleEditCollageItemClick: (index: number) => void
    handleEditModalClose: () => void
    handleCalendarTextChange: (text: string) => void
    selectedCollageItem: number | null
}

export const useAnniversaryTemplateBehavior = (state: EditPageState):
AnniversaryTemplateBehavior => {
  const [selectedCollageItem, setSelectedCollageItem] =
  useState<number | null>(null)

  function handleEditCollageItemClick(index: number) {
    setSelectedCollageItem(index)
  }

  function handleEditModalClose() {
    setSelectedCollageItem(null)
  }

  function handleCalendarTextChange(text: string) {
    state.updateItem(12, text)
  }
  return {
    handleEditCollageItemClick,
    handleEditModalClose,
    handleCalendarTextChange,
    selectedCollageItem,
  }
}

