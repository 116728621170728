import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import colors from "../colors";
import Header from "../header/Header";
import { css } from "@emotion/css";
import { validateEmail } from "../auth/Validator.util";
import { Spinner } from "../common/Spinner";
import { unsubscribeFromNewsletter } from "./SubscribeApi";


const Unsubscribe: React.FC = () => {
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isSendBtnSpin, setSendBtnSpin] = useState(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const isEmailSent = !!searchParams.has("confirmation")
  const isInvalid = !!searchParams.has("invalid")

  useEffect(() => {
    const p = searchParams.get("e") || ""
    const theEmail = atob(p)

    console.log("theEmail", theEmail);

    if ( theEmail ) {
      handleRequest(theEmail)
    } else {
      navigate("/unsubscribe?invalid")
    }
  }, [])


  async function handleRequest(email) {
    try {
      const result = validateEmail(email)
      if ( result ) {
        throw new Error("invalid email")
      }
      await unsubscribeFromNewsletter(email)
      navigate("/unsubscribe?confirmation")
      return
    } catch (error) {
      navigate("/unsubscribe?invalid")
    }
  }

  if (isEmailSent) {
    return (
      <div className="Unsubscribe">
        <Header />
        <h1>Unsubscribed from the list</h1>
        <p>
          You will no longer receive any emails from us.
        </p>
        <style>
          {`
            .Unsubscribe {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: ${colors.white2};
            }
            .Unsubscribe > h1 {
              margin-top: 3em;
            }
        `}
        </style>
      </div>
    )
  }

  if (isInvalid) {
    return (
      <div className="Unsubscribe">
        <Header />
        <h1>Invalid link</h1>
        <p>
          You have clicked to an expired or invalid link.
        </p>
        <style>
          {`
            .Unsubscribe {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: ${colors.white2}
            }
        `}
        </style>
      </div>
    )
  }

  return (
    <div className="Unsubscribe-Page">
      <Header />
      <div className="Unsubscribe">
        <Spinner />
        <style>
          {`
            .Unsubscribe-Page {
              height: 100%;
            }
            .Unsubscribe {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                text-align: left;
                gap: 1em;
                width: 100%;
                color: ${colors.white2};
                min-height: 70vh;
                padding-top: 2em;
            }
            .Unsubscribe-Header {
                font-size: 1.5em;
                margin-bottom: 0;
            }   
            .Unsubscribe-Form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 1em;
                padding: 1em;
            }
            .Unsubscribe-Form-Button {
                width: 100%;
                max-width: 200px;
            }
            .Unsubscribe > p {
                padding: 0 1em;
            }
        `}
        </style>
      </div>
    </div>
  )
};

export default Unsubscribe;
