import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../common/DefaultButton";
import { usePaymentResult } from "./usePaymentResult";

const PaymentResult: React.FC = () => {
  const navigate = useNavigate()
  const paymentResult = usePaymentResult()

  function handleGoToDashboard() {
    navigate("/dashboard")
  }

  function callGtagEvent() {
    // @ts-ignore
    window?.gtag("event", "conversion", {
      "send_to": "AW-11150381813/WGxyCLaZvq0YEPWl9cQp",
      "transaction_id": "",
    });
  }

  return <div className="PaymentResult">
    {paymentResult === "SUCCEEDED" && <div className="PaymentResult-Succed">
      <h1>Payment Succeed</h1>
      <p>Thank you for your payment</p>
      { callGtagEvent()}
    </div>}
    {paymentResult === "FAILED" && <div className="PaymentResult-Succed">
      <h1>Payment was failed</h1>
    </div>}
    {paymentResult === "CANCELED" && <div className="PaymentResult-Failed">
      <h1>Payment was canceled</h1>
    </div>
    }
    <div className="PaymentResult-GoToProfile-Btn">
      <DefaultButton size="medium"
        color="blue"
        text="Go to Dashboard" onClick={handleGoToDashboard} />
    </div>

    <style>
      {`
        .PaymentResult {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 8em;
          color: white;
          justify-content: center;
          padding-top: 5em;
        }
        .PaymentResult-Succed, .PaymentResult-Failed {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          align-text: center;
        }
        .PaymentResult-GoToProfile-Btn {
          margin-top: 1em;
        }

        @media only screen and (min-width: 750px) {
          .PaymentResult {

          }
        }
      `}
    </style>
  </div>;
};

export default PaymentResult;
