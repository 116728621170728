import React, { ChangeEvent, useEffect, useRef } from "react";
import colors from "../colors";

type Props = {
  onChange: (value: string) => void;
  onBlur?: () => void;
  defaultValue: string;
  placeholder?: string;
  maxWidth?: string;
  focus?: boolean;
  password?: boolean;
  textAlign?: string;
  isError?: boolean;
  autoComplete?: string;
  className?: string;
}

const Input: React.FC<Props> = ({ onChange, defaultValue,
  maxWidth= "500px", focus = false,
  password = false, textAlign= "center", placeholder = "",
  isError = false, onBlur = () => null, autoComplete = "",
  className = "Input" }) => {
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
    }
  }, [focus, ref]);

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  // @ts-ignore
  return (<><input ref={ref.current} onBlur={onBlur}
    placeholder={placeholder}
    autoComplete={autoComplete}
    className={className} onChange={handleChange} defaultValue={defaultValue}
    type={password ? "password" : ""} />
  <style>
    {`
        .${className} {
          min-height: 34px;
          background: inherit;
          color: white;
          font-size: 30px;
          width: 100%;
          min-width: fit-content;
          max-width: ${maxWidth};
          padding: 3px 5px 3px 5px;
          min-width: 30px;
          text-align: ${textAlign};
          border: ${isError ? "1px solid red" : "1px solid white"};
        }
        .${className}::placeholder {
          color: ${colors.white2};
          font-size: 25px;
        }
      `}
  </style>
  </>);
};

export default Input;
