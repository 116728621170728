import React from "react";
import colors from "../colors";
import { Footer } from "../homepage/Footer";
import LogoHeader from "../header/LogoHeader";

const SignInLinkSent: React.FC = () => {
  return <><div className="SignInLinkSent">
    <LogoHeader />
    <h4 className="success-header">
      Sign in link was sent to your email address ✉️
    </h4>
    <p className="not-received-info">
        Not received the email?<br /> Check your spam folder
        or <a href="/sign-in">try again</a>.
    </p>
    <style>
      {`
        .SignInLinkSent {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          gap: 1em;
          height: 80vh;
          padding-bottom: 5em;
        }
        .success-header {
          margin-top: 2em;
          color: ${colors.white2};
          font-size: 1.8em;
          font-weight: 600;
        }
        .not-received-info {
          color: ${colors.white2};
          text-align: center;
        }
        .not-received-info a {
            color: ${colors.white2};
            text-decoration: underline;
        }

        @media only screen and (max-width: 600px) {
          .success-header {
            font-size: 1.4em;
            text-align: center;
          }
        }
      `}
    </style>
  </div>
  <Footer />
  </>
};

export default SignInLinkSent;
