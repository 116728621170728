import React, { useEffect, useRef, useState } from "react";
import colors from "../colors";

type Props = {
  defaultText: string,
  onChange: (text: string) => void;
  onBlur: () => void;
  testId?: string;
  autoFocus?: boolean;
  placeholder?: string;
  minHeight?: string;
  isError?: boolean;
  className?: string;
}
const TextArea: React.FC<Props> = ({ defaultText, onChange, onBlur,
  testId = "text-area", autoFocus = false,
  placeholder = "", minHeight = "2vh", isError = false, className = "" }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if ( !autoFocus ) return;
    textAreaRef.current?.focus();
  }, [autoFocus]);

  const [rows, setRows] = useState(1);
  const textAreaHeightPadding = 10;

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>)
      : void {
    const value = event.target.value;
    onChange(value);
    setRows(1);
  }


  useEffect((function() {
    const scrollHeight = (textAreaRef.current?.scrollHeight ?? 1);
    // clientHeight is always 1 row height + 2x padding
    // because we are setting rows to 1 when user types
    const clientHeight = (textAreaRef.current?.clientHeight ?? 1);
    const calculatedRows = Math.round((scrollHeight - 2 *
            textAreaHeightPadding) /
        (clientHeight - 2 * textAreaHeightPadding) * rows);
    setRows(calculatedRows);
  }), [textAreaRef.current?.value]);

  function handleOnFocus(e: React.FocusEvent<HTMLTextAreaElement>) {
    e.currentTarget.setSelectionRange(
        e.currentTarget.value.length, e.currentTarget.value.length,
    )
  }

  return <>
    <textarea placeholder={placeholder}
      className={className}
      onFocus={handleOnFocus}
      onBlur={onBlur}
      defaultValue={defaultText}
      data-testid={testId} ref={textAreaRef} onChange={handleTextChange}
      rows={rows} />
    <style>
      {`
        textarea {
              padding: 0;
              width: 100%;
              min-height: ${minHeight};
              cursor: pointer;
              background: transparent;
              color: ${colors.white2};
              font-size: 2.4em;
              font-weight: 700;
              padding: ${textAreaHeightPadding}px 20px;
              font-family: 'Source Sans Pro', sans-serif;
              border: 1px dashed ${isError ? colors.red : colors.white2};
              box-sizing: border-box;
            }
            textarea::-webkit-scrollbar {
              display: none;
            }
            textarea:focus {
              border: none;
            }
      `}
    </style>
  </>;
};

export default React.memo(TextArea);
