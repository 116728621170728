import React from "react";
import colors from "../colors";

type Props = {
  onClick: () => void;
  icon: React.ReactNode;
  text: string;
}

const AddItemButton: React.FC<Props> = ({ onClick, icon, text }) => {
  return <>
    <button onClick={onClick} className="AddItemButton-Container">
      { icon }
      <span className="AddItemButton-Text">{ text } </span>
    </button>
    <style>
      {`
        .AddItemButton {
          padding: 3px;
          transform: scale(1.4);
        }
        .AddItemButton-Container:hover {
          background-color: ${colors.grayDark2};
        }
        .AddItemButton-Container {
          padding: 0.5em 1em;
          width: 8em;
          height: 8em;
          background-color: ${colors.grayDark};
          cursor: pointer;
          border-radius: 5px;
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 0.5em;
          margin: 0;
          box-shadow: 0px 0px 6px 0px rgba(43, 43, 43, 0.75);
        }
        .AddItemButton-Text {
          font-size: 15px;
          font-weight: bold;
          color: ${colors.white2};
        }
      `}
    </style>
  </>;
};

export default AddItemButton;
