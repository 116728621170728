import React from "react";
import { AiFillTwitterSquare, AiOutlineInstagram } from "react-icons/ai";
import { BsTiktok, BsYoutube } from "react-icons/bs";

export const SocialLinks = () => {
  return <span className="Social-Links">
    <a target="_blank"
      className="Social-Link" href="https://www.youtube.com/@LovePageio" rel="noreferrer">
      <BsYoutube color="white" size={30} />
    </a>
    <a target="_blank"
      className="Social-Link" href="https://www.twitter.com/lovepageio" rel="noreferrer">
      <AiFillTwitterSquare color="white" size={30} />
    </a>
    <a target="_blank"
      className="Social-Link" href="https://www.tiktok.com/@lovepage.io" rel="noreferrer">
      <BsTiktok color="white" size={22} />
    </a>
    <a target="_blank"
      className="Social-Link" href="https://www.instagram.com/lovepageio" rel="noreferrer">
      <AiOutlineInstagram color="white" size={30} />
    </a>
    <style>
      {`
        .Social-Links {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 1.5em;
        }
    `}
    </style>
  </span>
}
