import React from "react";

export const RefundDialogBody: React.FC = () => {
  const refundInfoMessage = "Please contact us at support@lovepage.io " +
  "to request a refund.\n\n" +
  "We will look at your case and process your refund within 3 business days."

  return <div className="RefundDialogBody">
    <p>
      {refundInfoMessage}
    </p>
    <style>
      {`
            .RefundDialogBody { 
                font-size: 1.2em;
                padding: 2em 1em 1em 1em;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                line-height: 1.5em;
            }
        `}
    </style>
  </div>
}
