import React from "react";
import DefaultButton from "./DefaultButton";
import { MdOutlineEmail } from "react-icons/md";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const EnterEmailButton: React.FC =() => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const icon = <MdOutlineEmail
    color={colors.white2}
    size={14} />

  function handleCreateAccountClick() {
    navigate("/create-account")
  }

  return <DefaultButton
    color="green"
    className="AuthHeader-Upgrade-Button-DefaultButton"
    size="small"
    icon={icon}
    onClick={handleCreateAccountClick} text={t("enter-email-prompt")} />
}
